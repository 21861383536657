export function useMobile() {
  const breakpoints = useBreakpoints(breakpointsTailwind)

  return {
    isMobile: breakpoints.smaller('md'),
  }
}

export function useOpenAndHoverOnMobile(element: Ref<HTMLElement>, options = {
  openOnHover: true,
}) {
  const { isMobile } = useMobile()
  const isOpenTmp = ref(false)

  const isHovering = options.openOnHover ? useElementHover(element) : undefined

  onClickOutside(element, () => {
    if (isMobile.value) {
      isOpenTmp.value = false
    }
  })

  const isOpen = computed({
    get: () => isMobile.value ? isOpenTmp.value : isHovering?.value || true,
    set: (value: boolean) => {
      if (isMobile.value) {
        isOpenTmp.value = value
      }
    },
  })

  return {
    isOpen,
  }
}
