<script setup lang="ts">
import type { Palette } from '@/utils/colors'

defineOptions({
  name: 'DColored',
})

const props = withDefaults(defineProps<{
  as?: string
  value?: number
  type?: 'background' | 'text'
  noStyle?: boolean
  colors?: Palette | string[] | Record<number, string> | Map<number, string>
}>(), {
  as: 'div',
  type: 'text',
})

function getColor() {
  const value = props.value || 0
  const colors = props.colors || PALETTE_COLORS

  if (Array.isArray(colors)) {
    const index = Math.min(value, colors.length - 1)
    return colors[index]
  }

  if (colors instanceof Map) {
    const key = Object.keys(colors).map(Number).find((key: number) => value <= key) || 0
    return colors.get(key) || ''
  }

  if (typeof colors === 'object') {
    const key = Object.keys(colors).map(Number).find((key: number) => value <= key) || 0
    return colors[key] || ''
  }

  return null
}

const styled = computed(() => {
  const color = getColor()

  if (props.type === 'background') {
    return {
      backgroundColor: color,
    } as Record<string, string>
  }
  return {
    color,
  } as Record<string, string>
})
</script>

<template>
  <component
    :is="as || 'div'"
    :style="!noStyle && styled"
    v-bind="$attrs"
  >
    <slot :style="styled" />
  </component>
</template>
