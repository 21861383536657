<script setup>
import cityZonesQuery from '@/graphql/queries/cityZones.gql'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const navigationStore = useNavigationStore()
const { translateFromData } = useLabelTranslation()
const { mapOptions } = useCyclabilityMapOptions()

const {
  result: cityZones,
  loading: loadingZones,
} = useQuery(
  cityZonesQuery,
  computed(() => ({
    city: route.params.citySlug,
  })),
)

const zones = computed(() => cityZones.value?.city?.zones)

const percent = computed({
  get: () => (route.params.percent ? Number(route.params.percent) : 10),
  set: (value) => {
    router.push({
      params: {
        ...route.params,
        percent: value,
      },
    })
  },
})

const options = reactive({
  dataScale: 5,
  geometryType: getGeometryTypeFromUrl(route.params.areas),
  percent,
})

const variables = computed(() => {
  return {
    vehicleType: VehicleTypesData.BIKE,
    zone: options.geometryType,
    percent: options.percent,
  }
})

const { data, loading } = useMetricData('tripsZone', variables)

onMounted(() => {
  globalEmitter.on('filters:reset', () => {
    options.dataScale = 5
    options.geometryType = getGeometryTypeFromUrl(route.params.areas)
    options.percent = 10
  })
})

onBeforeUnmount(() => {
  globalEmitter.off('filters:reset')
})
</script>

<template>
  <Teleport
    v-if="navigationStore.filtersIsMounted"
    to="#teleport-sidebar"
  >
    <CityMapSettings
      v-model:geometry-type="options.geometryType"
      v-model:data-scale="options.dataScale"
      v-model:percent="options.percent"
      :geometries-options="zones?.map(geometry => ({
        value: geometry.name,
        text: translateFromData('geometry', geometry.name),
      }))
      "
      :geometries-loading="loadingZones"
    />
  </Teleport>

  <FlowMap
    :data="data"
    :loading="loading || loadingZones"
    :zoom="mapOptions.zoom"
    :center="mapOptions.center"
    :zones="zones"
    :options="options"
    :legend-title="t('Trips')"
  />
</template>
