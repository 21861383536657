import type { CellsType, GeometryType } from '@/types/index.types'
import type { CellsData } from '@/types/maps.types'
import type { LngLatLike } from 'maplibre-gl'
import type { ClassBreaks } from './classBreaks'
import type { Network, NetworkData, RoadLines } from './cyclabilityNetwork'
import type { AirPollutionMetric, AirQualityMetric, RoadCycleRapMetric } from './cyclabilityRoads'
import { ROAD_TYPES } from '@cd/screen-data'
import defu from 'defu'

import { AUTO_VALUE } from './cyclability'

interface CyclabilityDataBase {
  classbreaks: ClassBreaks
  labels?: string[]
  type?: string
  metric?: string
}

interface CyclabilityDataAreas extends CyclabilityDataBase {
  data: CellsData
  type: CellsType
}

interface CyclabilityDataNetwork extends CyclabilityDataBase {
  roads: Network
  data: NetworkData
  roadsVisible?: number[]
  roadsVisibleClassBreaks?: ClassBreaks
}

interface CyclabilityDataRoads extends CyclabilityDataBase {
  geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>
  outline?: {
    classbreaks: ClassBreaks
    labels: string[]
    metric: string
  }
}

export interface CyclabilityData {
  areas?: CyclabilityDataAreas
  network?: CyclabilityDataNetwork
  roads?: CyclabilityDataRoads
  pois?: { geojson: GeoJSON.FeatureCollection<GeoJSON.Point> }
}

export type CyclabilityDataLayer = CyclabilityDataAreas
  | CyclabilityDataNetwork
  | CyclabilityDataRoads

export const useCyclabilityMapOptions = createSharedComposable(() => {
  const cityStore = useCityStore()
  const { city } = storeToRefs(cityStore)

  const mapOptions = reactive<{
    zoom: number
    center?: LngLatLike
  }>({
    zoom: 7,
    center: undefined,
  })

  watch(city, (city, lastCity) => {
    if (city && city.name !== lastCity?.name) {
      mapOptions.center = [city.center.lng, city.center.lat]
      mapOptions.zoom = city.default_zoom
    }
  }, { immediate: true })

  return {
    mapOptions,
  }
})

interface CyclabilityDataOptions {
  excludeData: ('network' | 'areas' | 'roads')[]
  excludeMetric: ('network')[]
}

export const CYCLERAP_CLASSBREAKS = makeClassBreaks([1, 2, 3, 4, Infinity], PALETTE_COLORS_CYCLERAP)
export const POTHOLE_CLASSBREAKS = makeClassBreaks([0, 1, Infinity], [PALETTE_COLORS_CYCLERAP[0], PALETTE_COLORS_CYCLERAP[2]])
export const AIR_QUALITY_CLASSBREAKS = makeClassBreaks([1, 2, 3, 4, 5, Infinity], undefined, ['limegreen', 'orange', 'orangered'])
export const AIR_POLLUTION_CLASSBREAKS = makeClassBreaks([0, 5, 15, 20, 25, 50, 100, 200], undefined, ['lightgreen', 'navy'])
export const CYCLERAP_LEGEND_LABELS = ['low', 'medium', 'high', 'extreme']
export const POTHOLE_LEGEND_LABELS = ['no_pothole', 'pothole_detected']
export const AIR_QUALITY_LEGEND_LABELS = ['very_low', 'low', 'medium', 'medium+', 'high', 'very_high']

export function useCyclabilityData(options?: Partial<CyclabilityDataOptions>) {
  const { translateIfExists } = useLabelTranslation()
  const cityStore = useCityStore()
  const { slug, isLoading } = storeToRefs(cityStore)
  const { mapOptions } = useCyclabilityMapOptions()

  const loading = ref(0)

  const definedOptions = defu<Partial<CyclabilityDataOptions>, [CyclabilityDataOptions]>(options, {
    excludeData: [],
    excludeMetric: [],
  })

  const {
    areaActiveFile,
    geometryType,
    networkType,
    isNetworkCycleInfra,
  } = useCyclability(slug, mapOptions)

  const data = reactive<CyclabilityData>({})

  if (!definedOptions.excludeData.includes('areas')) {
    const { areasData, areasMetricActiveFile, loading: areasLoading } = useCyclabilityAreas(slug)

    watch([geometryType, areasData, areasMetricActiveFile], ([geometryType, areasData, areasMetricActiveFile]) => {
      if (geometryType === AUTO_VALUE) {
        geometryType = areaActiveFile.value?.name || undefined
      }

      if (geometryType && isCellsType(geometryType as GeometryType)) {
        const { breaks, colors, labels } = areasMetricActiveFile || { breaks: [] }

        data.areas = {
          data: areasData,
          classbreaks: makeClassBreaks(breaks, colors),
          labels,
          type: geometryType as CellsType,
          metric: areasMetricActiveFile?.metric,
        }
      } else {
        data.areas = undefined
      }
    }, { immediate: true })

    watch(areasLoading, (areasLoading) => {
      if (areasLoading) {
        loading.value++
      } else {
        loading.value--
      }
    })
  }

  if (!definedOptions.excludeData.includes('network')) {
    const { network, networkData, networkRoadsIndexesWithData, networkMetricActiveFile, loading: networkLoading } = useCyclabilityNetwork(slug)
    const networkRoadClassBreaks = makeClassBreaks(
      [...Object.keys(ROAD_TYPES).map(n => Number.parseInt(n)), ROAD_TYPES.length],
      ROAD_TYPES.map((r: keyof typeof PALETTE_COLORS_NETWORK_CLASS) => PALETTE_COLORS_NETWORK_CLASS[r]),
    )

    function filterRoadsByIndex<T extends object>(toFilter: T[], {
      filterVisible = false,
      filterCycleInfra = false,
    }: {
      filterVisible?: boolean
      filterCycleInfra?: boolean
    } = {}): T[] {
      return toFilter.filter((n, i) => {
        // roads with data
        const isVisible = !filterVisible || (filterVisible && networkRoadsIndexesWithData.value.includes(i))

        // roads who is in cycle infra type
        const isCycleInfra = !filterCycleInfra || (filterCycleInfra && enumToArray(RoadCyclingInfraTypes).includes(i))

        if ('lines' in n && Array.isArray(n.lines)) {
          // filter roads without geometries
          return isVisible && isCycleInfra && n.lines.length > 0
        }

        return isVisible && isCycleInfra
      })
    }

    watch([networkType, network, networkData, networkMetricActiveFile], ([networkType, network, networkData, networkMetricActiveFile]) => {
      if (networkType) {
        if (!definedOptions.excludeMetric.includes('network') && networkMetricActiveFile) {
          const { breaks, colors, labels, metric } = networkMetricActiveFile

          data.network = {
            roads: filterRoadsByIndex(network, { filterCycleInfra: isNetworkCycleInfra(networkType), filterVisible: true }),
            data: networkData,
            classbreaks: makeClassBreaks(breaks, colors),
            labels,
            metric,
            type: networkType,
          }
        } else {
          const filteredRoadTypes = filterRoadsByIndex<RoadLines>(network, { filterCycleInfra: isNetworkCycleInfra(networkType) })
          const filteredRoadTypesIndexes = filteredRoadTypes.map(n => n.index)

          data.network = {
            roads: filterRoadsByIndex(network, { filterCycleInfra: isNetworkCycleInfra(networkType) }),
            data: networkData,
            classbreaks: networkRoadClassBreaks,
            roadsVisible: filteredRoadTypesIndexes,
            roadsVisibleClassBreaks: networkRoadClassBreaks.filter(c => filteredRoadTypesIndexes.includes(c.from)),
            labels: filteredRoadTypes.map(n => translateIfExists(`cyclability.data.roads_type.${n.roadType}`, n.roadType)),
            metric: 'road',
            type: networkType,
          }
        }
      } else {
        data.network = undefined
      }
    }, { immediate: true })

    watch(networkLoading, (networkLoading) => {
      if (networkLoading) {
        loading.value++
      } else {
        loading.value--
      }
    })
  }

  if (!definedOptions.excludeData.includes('roads')) {
    const {
      roadsGeojson,
      poisGeojson,
      pathsMetric,
      loading: roadsLoading,
    } = useCyclabilityRoads(slug)

    watch([pathsMetric, roadsGeojson, poisGeojson], ([pathsMetric, roadsGeojson, poisGeojson]) => {
      if (pathsMetric) {
        data.roads = {
          geojson: roadsGeojson,
          classbreaks: [],
          labels: undefined,
          metric: pathsMetric,
        }

        if (cycleRapMetrics.includes(pathsMetric as RoadCycleRapMetric)) {
          // Road types
          data.roads.classbreaks = CYCLERAP_CLASSBREAKS
          data.roads.labels = CYCLERAP_LEGEND_LABELS.map(label => translateIfExists(`cyclability.data.cyclerap.${label}`, label))
        } else if (pathsMetric === 'pothole') {
          // Pothole
          data.roads.classbreaks = POTHOLE_CLASSBREAKS
          data.roads.labels = POTHOLE_LEGEND_LABELS.map(label => translateIfExists(`cyclability.data.pothole.${label}`, label))
        } else if (airQualityMetrics.includes(pathsMetric as AirQualityMetric)) {
          // Air quality
          data.roads.classbreaks = AIR_QUALITY_CLASSBREAKS
          data.roads.labels = AIR_QUALITY_LEGEND_LABELS.map(label => translateIfExists(`cyclability.data.airQuality.${label}`, label))
        } else if (airPollutionMetrics.includes(pathsMetric as AirPollutionMetric)) {
          // Air pollution
          data.roads.classbreaks = AIR_POLLUTION_CLASSBREAKS
          data.roads.outline = {
            classbreaks: AIR_QUALITY_CLASSBREAKS,
            labels: AIR_QUALITY_LEGEND_LABELS.map(label => translateIfExists(`cyclability.data.airQuality.${label}`, label)),
            metric: `${pathsMetric}Quality`,
          }
        } else {
          // Other metrics
          const { classBreaks: pathsClassBreaks } = useClassBreaks(useExtractMean(roadsGeojson, pathsMetric), 5, ['lightgreen', 'navy'])

          data.roads.classbreaks = pathsClassBreaks.value
        }
      } else {
        data.roads = undefined
      }

      if (poisGeojson) {
        data.pois = {
          geojson: poisGeojson,
        }
      } else {
        data.pois = undefined
      }
    })

    watch(roadsLoading, (roadsLoading) => {
      if (roadsLoading) {
        loading.value++
      } else {
        loading.value--
      }
    })
  }

  return {
    data,
    loading: computed(() => loading.value > 0 || isLoading.value),
    mapOptions,
  }
}

export function useCyclabilityLabels() {
  const { translateIfExists } = useLabelTranslation()

  return {
    getCycleRapLabel: (value?: number) => value ? CYCLERAP_LEGEND_LABELS.map(l => translateIfExists(`cyclability.data.cyclerap.${l}`, l))[value - 1] : undefined,
    getPotholeLabel: (value?: number) => value !== undefined ? POTHOLE_LEGEND_LABELS.map(l => translateIfExists(`cyclability.data.pothole.${l}`, l))[value] : undefined,
    getAirQualityLabel: (value?: number) => value !== undefined ? AIR_QUALITY_LEGEND_LABELS.map(l => translateIfExists(`cyclability.data.airQuality.${l}`, l))[value] : undefined,
  }
}
