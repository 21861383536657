<script setup lang="ts">
import type { Map, MapLayerMouseEvent, SourceSpecification } from 'maplibre-gl'
import { useSource } from './layerSource'

defineOptions({
  name: 'MapLibreSource',
})

// see for source properties https://maplibre.org/maplibre-style-spec/sources/
const props = withDefaults(defineProps<MapLibreSourceProps>(), {
  source: () => ({
    type: 'geojson',
    data: newFeatureCollection(),
  }),
})

const emit = defineEmits(['click'])

interface MapLibreSourceProps {
  id: string
  source: SourceSpecification
}

const map = inject<Ref<Map | undefined>>('map', ref())
const { id } = toRefs(props)

const { removeSource, updateSource, getSource } = useSource(id)

updateSource(props.source)

onMounted(() => {
  map.value?.on('click', id.value, onClick)
})

onBeforeUnmount(async () => {
  get(map)?.off('click', get(id), onClick)

  await nextTick()
  removeSource()
})

function onClick(event: MapLayerMouseEvent) {
  emit('click', event)
}
</script>

<template>
  <slot :get-source="() => getSource()" />
</template>
