import type { GeometryType } from '@/types/index.types'
import type { LngLatLike } from 'maplibre-gl'
import type { Reactive } from 'vue'

type AvailableFilters<T = string> = {
  name: T
  label: string
}[]

enum LayerTypes {
  PATHS = 'roads',
  AREAS = 'areas',
  NETWORK = 'network',
}

type LayerType = `${LayerTypes}`
type ZoomBounds = [number, number]

interface MetricDefinition {
  metric: string
  breaks: number[]
  labels?: string[]
  colors?: string[]
  filePath?: string // full file name
}

interface FileDefinitionBase {
  name: string
  type?: 'geometryType' | 'networkType'
  filePath: string
  metrics: MetricDefinition[]
}

interface FileDefinitionVariant extends FileDefinitionBase {
  variant: string
}

interface FileDefinitionWithZoomBounds extends FileDefinitionBase {
  zoomBounds: ZoomBounds
}

interface FileDefinitionWithZoomBoundsVariant extends FileDefinitionVariant, FileDefinitionWithZoomBounds { }

type FileDefinition = FileDefinitionBase | FileDefinitionVariant | FileDefinitionWithZoomBounds | FileDefinitionWithZoomBoundsVariant

interface LayerFileDefinition<T extends LayerType> {
  layer: T
  file: FileDefinitionBase
}

interface LayerFilesDefinition<T extends LayerType> {
  layer: T
  files: FileDefinitionBase[] | FileDefinitionVariant[] | FileDefinitionWithZoomBounds[] | FileDefinitionWithZoomBoundsVariant[]
}

type LayerDefinition<T extends LayerType = LayerType> = LayerFileDefinition<T> | LayerFilesDefinition<T>
type ConfigFile<T extends LayerType = LayerType> = LayerDefinition<T>[]

const FILE_PATH_BASE = import.meta.env.VITE_SCREEN_BUCKET || 'https://screen-data-staging.s3.nl-ams.scw.cloud/prod'
export const NETWORK_CYCLE_INFRA_SUFFIX = '_cycle_infra'
export const AUTO_VALUE = 'auto'
export type AutoValue = typeof AUTO_VALUE

function forEachConfigFiles(config: ConfigFile, itterator: ({
  layer,
  file,
}: {
  layer: LayerType
  file: FileDefinition
}) => void | false): void {
  let stopClaim = false

  for (const n of config) {
    if ('files' in n) {
      if (stopClaim) {
        break
      }

      for (const f of n.files) {
        if (itterator({
          layer: n.layer,
          file: f,
        }) === false) {
          stopClaim = true
          break
        }
      }
    } else {
      if (itterator({
        layer: n.layer,
        file: n.file,
      }) === false) {
        break
      }
    }
  }
}

// return the first config file that matches the itterator
function findFileInConfig(config: ConfigFile, itterator: (file: FileDefinition) => boolean): FileDefinition | null {
  let file = null

  forEachConfigFiles(config, ({ file: f }) => {
    if (itterator(f)) {
      file = f
      return false
    }
  })

  return file
}

function inZoomBounds(f: FileDefinition, zoom: number): boolean {
  if ('zoomBounds' in f && f.zoomBounds) {
    const [min, max] = f.zoomBounds
    return (min === -1 || zoom >= min) && (max === -1 || zoom <= max)
  }

  return false
}

export const useCyclability = createSharedComposable((citySlug: Ref<string | null>, mapOptions?: Reactive<{
  zoom: number
  center?: LngLatLike
  // bounds?: [[number, number], [number, number]]
}>) => {
  const { translateFromData } = useLabelTranslation()

  const loading = ref(true)
  const configFile = shallowRef<ConfigFile | null>()

  const {
    geometryType,
    areasMetric,
    networkType,
    networkMetric,
  } = useReactiveParams({
    geometryType: { name: 'geometry' },
    areasMetric: { name: 'geoMetric' },
    networkType: { name: 'network' },
    networkMetric: { name: 'networkMetric', defaultValue: null },
  })

  const availableGeometries = shallowRef<AvailableFilters<GeometryType | AutoValue>>([])
  const availableAreasMetrics = shallowRef<AvailableFilters>()
  const availableNetworks = shallowRef<AvailableFilters>([])
  const availableNetworkMetrics = shallowRef<AvailableFilters>()

  const areasConfig = shallowRef<ConfigFile<LayerTypes.AREAS>>([])
  const networksConfig = shallowRef<ConfigFile<LayerTypes.NETWORK>>([])

  const areaActiveFile = shallowRef<FileDefinitionWithZoomBounds | null>()
  const networkActiveFile = shallowRef<FileDefinitionWithZoomBoundsVariant | null>()

  const { translateIfExists } = useLabelTranslation()

  async function getConfigFile() {
    loading.value = true

    try {
      const results = await fetch(urlToFetch('/metrics.json'))
      configFile.value = await results.json() as ConfigFile<LayerType>
      prepareCityConfig(configFile.value)
    } catch (e) {
      console.error(e)
      reset()
    }

    loading.value = false
  }

  function isNetworkCycleInfra(networkType: string | null) {
    return networkType?.endsWith('_cycle_infra')
  }

  function cleanNetworkType(networkType: string | null) {
    return networkType?.replace('_cycle_infra', '')
  }

  function reset() {
    availableGeometries.value = []
    availableNetworks.value = []
    areasConfig.value = []
    networksConfig.value = []
    availableAreasMetrics.value = undefined
    availableNetworkMetrics.value = undefined
  }

  async function prepareCityConfig(config: ConfigFile<LayerType>) {
    reset()

    const availableGeometriesTmp: GeometryType[] = []
    const availableNetworksTmp: string[] = []

    const areas = config.filter((c): c is LayerDefinition<LayerTypes.AREAS> => c.layer === LayerTypes.AREAS)
    const networks = config.filter((c): c is LayerDefinition<LayerTypes.NETWORK> => c.layer === LayerTypes.NETWORK)

    forEachConfigFiles(areas, ({ file }) => {
      if (file.type === 'geometryType' && !availableGeometriesTmp.includes(file.name as GeometryType)) {
        availableGeometriesTmp.push(file.name as GeometryType)
      }
    })

    forEachConfigFiles(networks, ({ file }) => {
      if (file.type === 'networkType' && !availableNetworksTmp.includes(file.name)) {
        availableNetworksTmp.push(file.name)
      }
    })

    // save filtered configs
    areasConfig.value = areas
    networksConfig.value = networks

    // TODO: adapt default values with last selected values
    // setup selected network value
    if (!networkType.value || !availableNetworksTmp.includes(networkType.value)) {
      networkType.value = availableNetworksTmp[0]
    }

    // setup selected geometry value
    const lastSelectedGeometry = geometryType.value
    const hasAuto = availableGeometriesTmp.length > 1

    if (
      lastSelectedGeometry === undefined
      || ((lastSelectedGeometry && !availableGeometriesTmp.includes(lastSelectedGeometry as GeometryType))
        && (hasAuto && lastSelectedGeometry !== AUTO_VALUE))
    ) {
      geometryType.value = availableGeometriesTmp[0]
    }

    // setup available filters
    availableGeometries.value = [
      ...availableGeometriesTmp.map(n => ({
        name: n as GeometryType,
        label: translateFromData('geometry', n),
      })),
    ]

    // null value is for the auto selection and added directly to the list in the selector options
    availableNetworks.value = availableNetworksTmp.reduce((acc, n) => {
      // filter out full osm network type to keep only the osm bike network type
      if (n !== 'osm') {
        acc.push({
          name: n,
          label: translateIfExists(`cyclability.data.networkType.${n}`, n),
        })
      }
      acc.push({
        name: `${n}${NETWORK_CYCLE_INFRA_SUFFIX}`,
        label: translateIfExists(`cyclability.data.networkType.${n}${NETWORK_CYCLE_INFRA_SUFFIX}`, `${n}${NETWORK_CYCLE_INFRA_SUFFIX}`),
      })

      return acc
    }, [] as AvailableFilters)

    updateActiveFile({
      geometryType: geometryType.value,
      networkType: networkType.value,
    })
  }

  function checkFileNameAndZoomBounds<T = string | null>(f: FileDefinition, name: T) {
    if (
      'zoomBounds' in f
      && ((name === AUTO_VALUE && !('variant' in f))
        || ('variant' in f && f.name === name))
    ) {
      return inZoomBounds(f, mapOptions?.zoom || 0)
    }

    return f.name === name
  }

  function updateActiveFile({
    geometryType,
    networkType,
  }: {
    geometryType?: GeometryType | string | null
    networkType?: string | null
  }) {
    if (geometryType !== undefined) {
      const ac = findFileInConfig(areasConfig.value, f => checkFileNameAndZoomBounds(f, geometryType))
      const lac = areaActiveFile.value

      if (ac && (!lac || lac.filePath !== ac.filePath)) {
        areaActiveFile.value = ac as FileDefinitionWithZoomBounds
        availableAreasMetrics.value = ac?.metrics.map(m => ({
          name: m.metric,
          label: translateIfExists(`cyclability.data.areas.${m.metric}`, m.metric),
        })) || []
      }
    }

    if (networkType !== undefined) {
      const nc = findFileInConfig(networksConfig.value, f => checkFileNameAndZoomBounds(f, cleanNetworkType(networkType)))
      const lnc = networkActiveFile.value

      if (nc && (!lnc || lnc.filePath !== nc.filePath)) {
        networkActiveFile.value = nc as FileDefinitionWithZoomBoundsVariant
        availableNetworkMetrics.value = nc.metrics.map(m => ({
          name: m.metric,
          label: translateIfExists(`cyclability.data.network.${m.metric}`, m.metric),
        })) || []
      }
    }
  }

  function urlToFetch(path: string) {
    return `${FILE_PATH_BASE}/${citySlug.value}/${path.indexOf('/') === 0 ? path.slice(1) : path}`
  }

  onMounted(() => {
    whenever(citySlug, getConfigFile, { immediate: true })
    whenever(networkType, networkType => updateActiveFile({ networkType }))
    whenever(geometryType, geometryType => updateActiveFile({ geometryType }))
    whenever(() => mapOptions, () => {
      updateActiveFile({
        geometryType: geometryType.value,
        networkType: networkType.value,
      })
    }, { deep: true })
  })

  return {
    loading,
    geometryType,
    networkType,
    networkMetric,
    areasMetric,
    availableGeometries,
    availableNetworks,
    areasConfig,
    networksConfig,
    areaActiveFile,
    networkActiveFile,
    availableAreasMetrics,
    availableNetworkMetrics,
    isNetworkCycleInfra,
    urlToFetch,
  }
})
