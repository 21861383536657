type OSMRoadType = string
type CityRoadType = string
export type RoadType = OSMRoadType | CityRoadType

interface RoadFeaturePropertiesBase {
  name: string
}

export interface PoiFeatureProperties extends RoadFeaturePropertiesBase {
  picture: string
  path: string | null
}

export interface RoadFeatureProperties extends RoadFeaturePropertiesBase {
  soundAvg?: number
  soundMax?: number
  pm1?: number
  pm2_5?: number
  pm10?: number
  NO2?: number
  pm1Quality?: number
  pm2_5Quality?: number
  pm10Quality?: number
  NO2Quality?: number
  airQuality?: number
  pothole?: number
  score?: number
  scoreBicycle?: number
  scoreBicycleToBicycle?: number
  scoreVehicleToBicycle?: number
  scoreBicycleToPedestrian?: number
  scoreValue?: number
  bumps?: number
  treatment?: string
  treatmentScoreValue?: number
  treatmentScoreRisk?: number
  treatmentImpact?: number
  picture?: string
}

export type RoadCycleRapMetric = keyof Pick<RoadFeatureProperties, 'score' | 'scoreBicycle' | 'scoreBicycleToBicycle' | 'scoreVehicleToBicycle' | 'scoreBicycleToPedestrian' >
export type RoadMetric = keyof Omit<RoadFeatureProperties, 'name' | 'treatment' | 'treatmentScore' | 'treatmentScoreValue' | 'treatmentScoreRisk' | 'picture' | 'scoreValue' >
export type AirQualityMetric = keyof Pick<RoadFeatureProperties, 'pm1Quality' | 'pm2_5Quality' | 'pm10Quality' | 'NO2Quality' | 'airQuality'>
export type AirPollutionMetric = keyof Pick<RoadFeatureProperties, 'pm1' | 'pm2_5' | 'pm10' | 'NO2'>

export const cycleRapMetrics: RoadCycleRapMetric[] = [
  'score',
  'scoreBicycle',
  'scoreBicycleToBicycle',
  'scoreVehicleToBicycle',
  'scoreBicycleToPedestrian',
]

export const airPollutionMetrics: AirPollutionMetric[] = [
  'pm1',
  'pm2_5',
  'pm10',
  'NO2',
]

export const airQualityMetrics: AirQualityMetric[] = [
  'pm1Quality',
  'pm2_5Quality',
  'pm10Quality',
  'NO2Quality',
  'airQuality',
]

export const roadMetrics: RoadMetric[] = [
  ...cycleRapMetrics,
  'treatmentImpact',
  'soundAvg',
  'soundMax',
  ...airPollutionMetrics,
  'bumps',
  'pothole',
]

export const useCyclabilityRoads = createSharedComposable((citySlug: Ref<string | null>) => {
  const { urlToFetch } = useCyclability(citySlug)
  const { translateIfExists } = useLabelTranslation()
  const poisGeojson = ref(newFeatureCollection<GeoJSON.Point, PoiFeatureProperties>())
  const roadsGeojson = ref(newFeatureCollection<GeoJSON.LineString, RoadFeatureProperties>())

  const availablePathsMetrics = computed(() => {
    if (roadsGeojson.value.features.length === 0) {
      return []
    }

    const metrics = roadMetrics.filter((metric) => {
      const road = roadsGeojson.value.features.find(road => road.properties?.[metric] !== undefined)
      return road !== undefined
    })

    return metrics.map(metric => ({
      name: metric,
      label: translateIfExists(`cyclability.data.roads.${metric}`, metric),
    }))
  })

  const pathsMetric = ref<RoadMetric>('score')

  const { isFetching, error, onFetchResponse } = useFetch<GeoJSON.FeatureCollection<GeoJSON.Point>>(urlToFetch('/road.geojson'))

  onFetchResponse(async (response) => {
    const geojson = await response.json()
    const tmpPoisGeojson = newFeatureCollection<GeoJSON.Point, PoiFeatureProperties>()
    const tmpRoadsGeojson = newFeatureCollection<GeoJSON.LineString, RoadFeatureProperties>()

    for (const feature of geojson.features) {
      if (!feature.properties) {
        continue
      }

      if (feature.geometry.type === 'Point') {
        feature.properties = {
          ...feature.properties,
          name: feature.properties.picture,
          path: feature.properties.picture ? `https://screen-data.s3.fr-par.scw.cloud/barcelona/pictures/${feature.properties.picture}` : null,
          // urlToFetch(`/pictures/${feature.properties.picture}`),
        }

        tmpPoisGeojson.features.push(feature)
      } else {
        const roadProperties = feature.properties as RoadFeatureProperties

        feature.properties = {
          ...roadProperties,
          ...roadMetrics.reduce((acc, metric) => {
            if (roadProperties[metric] === undefined) {
              return acc
            }
            acc[metric] = roadProperties[metric]
            return acc
          }, {} as RoadFeatureProperties),
          name: roadProperties.picture,
        }

        tmpRoadsGeojson.features.push(feature)
      }
    }

    poisGeojson.value = tmpPoisGeojson
    roadsGeojson.value = tmpRoadsGeojson
  })

  watch(error, (error) => {
    if (error) {
      console.error(error)
    }
  })

  return {
    loading: isFetching,
    roadsGeojson,
    poisGeojson,
    availablePathsMetrics,
    pathsMetric,
  }
})
