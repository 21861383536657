<script setup lang="ts">
import type { City } from '@/stores/city'
import type { Provider } from '@/stores/provider'

const props = defineProps<{
  menu: object
  label?: string
  provider?: Provider
  city?: City
}>()

const element = ref()
const { t } = useI18n()
const { providerName } = useProviderName()
const { getCityNameLocalized } = useCitiesStore()

const { isMobile } = useMobile()
const { isOpen } = useOpenAndHoverOnMobile(element, { openOnHover: false })

const debounced = refDebounced(isOpen, 300)

const name = computed(() => {
  if (props.label !== null) {
    return props.label
  }

  if (props.city) {
    return getCityNameLocalized(props.city.name)
  }

  if (props.provider) {
    return providerName(props.provider)
  }

  return ''
})

function onBeforeEnter(el: Element) {
  (el as HTMLElement).style.height = '0'
}
function onEnter(el: Element) {
  (el as HTMLElement).style.height = `${el.scrollHeight}px`
}
function onBeforeLeave(el: Element) {
  (el as HTMLElement).style.height = `${el.scrollHeight}px`
}
function onLeave(el: Element) {
  (el as HTMLElement).style.height = '0'
}
</script>

<template>
  <div
    ref="element"
    @touchend="() => (isOpen = !isOpen)"
  >
    <div
      class="text-white gradient md:rounded-t-md"
      :class="{
        'md:rounded-b-none': isOpen,
        'md:rounded-b-md': !debounced,
      }"
    >
      <div class="md:h-12 h-14 font-bold flex items-center">
        <SidebarNavigationHamburger
          v-if="isMobile"
          :open="isOpen"
        />
        <div class="md:ml-4 flex-1 font-title font-bold">
          <p class="text-lg uppercase">
            {{ name }}
          </p>
        </div>
        <div class="hidden md:block">
          <DFlag
            v-if="city"
            :country-code="city.countryCode"
          />
          <img
            v-else-if="provider"
            class="m-2 inline-block h-7 w-7"
            :src="`${CDN_PATH}/providers/${provider.slug}.jpg`"
            :alt="t('provider_logo', { provider: name })"
          >
        </div>
      </div>
    </div>

    <Transition
      name="nested"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div
        v-if="isOpen"
        class="gradient md:rounded-b-md bottom-14 md:bottom-auto w-full"
        :class="{
          'absolute z-menu': isMobile,
        }"
      >
        <slot />
        <SidebarNavigationMenu :menu="menu" />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

/**
.nested-enter-from,
.nested-leave-to {
  opacity: 0;
}
*/
</style>
