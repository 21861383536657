import { ROAD_TYPES } from '@cd/screen-data'

type OSMRoadType = string
type CityRoadType = string
export type RoadType = OSMRoadType | CityRoadType

export interface RoadLines {
  roadType: RoadType
  lines: [[number, number][]]
  index: number
}

export type Network = RoadLines[]
export type NetorkLineID = `${number}_${number}`
export type NetworkData = Map<NetorkLineID, string> | Map<NetorkLineID, number>

export const useCyclabilityNetwork = createSharedComposable((citySlug: Ref<string | null>) => {
  const { networkActiveFile, urlToFetch, networkMetric, availableNetworkMetrics } = useCyclability(citySlug)

  const loading = ref(0)
  const network = shallowRef<RoadLines[]>([])
  const networkRoadsIndexesWithData = ref<number[]>([])
  const networkData = shallowRef<NetworkData>(new Map())

  const networkMetricActiveFile = computed(() => {
    return networkActiveFile.value?.metrics.find(m => m.metric === networkMetric.value)
  })

  async function getCyclabilityNetwork() {
    loading.value++
    const url = urlToFetch(networkActiveFile.value?.filePath || '')

    try {
      const result: RoadLines[] | undefined = await fetchNetwork(url)

      network.value = result?.map(({ roadType, lines }) => {
        return {
          roadType,
          lines: lines || [],
          index: ROAD_TYPES.findIndex((d) => {
            return d === roadType
          }),
        }
      }) || []
    } catch {
      network.value = []
    }

    getCyclabilityNetworkData()
    loading.value--
  }

  async function getCyclabilityNetworkData() {
    loading.value++

    if (!networkMetric.value || !networkMetricActiveFile.value?.filePath) {
      loading.value--
      networkData.value = new Map()
      return
    }

    try {
      const url = urlToFetch(networkMetricActiveFile.value?.filePath)
      const raw = await fetchParquet(url)
      const key = Object.keys(raw[0])[0]
      const data = raw.map(record => record[key])
      const map = new Map()
      const visibleTmp: number[] = []
      let pos = 0

      for (let i = 0; i < network.value.length; i++) {
        const { lines } = network.value[i]

        for (let y = 0; y < lines.length; y++) {
          if (data[y + pos] !== null && data[y + pos] !== undefined) {
            map.set(`${i}_${y}`, data[y + pos])

            if (!visibleTmp.includes(i)) {
              visibleTmp.push(i)
            }
          }
        }

        pos += lines.length
      }

      networkRoadsIndexesWithData.value = visibleTmp
      networkData.value = map
    } catch (error) {
      console.error('error when fetching network data: ', error)
      networkData.value = new Map()
    }

    loading.value--
  }

  whenever(networkActiveFile, getCyclabilityNetwork, { immediate: true })
  whenever(networkMetricActiveFile, getCyclabilityNetworkData)
  whenever(availableNetworkMetrics, (availableNetworkMetrics) => {
    const lastValue = networkMetric.value

    if (availableNetworkMetrics.map(m => m.name).includes(lastValue as string)) {
      networkMetric.value = lastValue
    } else {
      networkMetric.value = null
    }
  }, { immediate: true })

  return {
    loading: computed(() => loading.value > 0),
    network,
    networkData,
    networkRoadsIndexesWithData,
    networkMetricActiveFile,
    getCyclabilityNetwork,
    getCyclabilityNetworkData,
  }
})
