<script setup lang="ts">
import type { Map as MapLibre } from 'maplibre-gl'
import { type LayerInfo, LayerRegistryKey } from './maplibreUtils'

const map = inject<Ref<MapLibre | undefined>>('map', ref())
const layers = ref<LayerInfo[]>([])
const slots = useSlots()

// Récupérer l'ordre des layers dans le slot
const slotLayersOrder = computed(() => {
  // Récupérer les composants du slot default
  const defaultSlotComponents = slots.default?.() || []

  // Trouver tous les composants MapLibreLayer et extraire leurs layer-id
  const layerIds = defaultSlotComponents
    .filter((vnode) => {
      const type = vnode.type as any
      return type && typeof type === 'object' && type.name
    })
    .map(vnode => vnode.props?.['layer-id'] || vnode.props?.id)
    .filter(id => id != null)

  return layerIds
})

// Trier les layers en respectant à la fois l'ordre du slot et les beforeId
const orderedLayers = computed(() => {
  // Créer un Map pour stocker l'ordre des layers du slot
  const slotOrder = new Map(
    slotLayersOrder.value.map((id, index) => [id, index]),
  )

  return [...layers.value].sort((a, b) => {
    // Si les deux layers ont un beforeId, on les compare
    if (a.beforeId && b.beforeId) {
      if (a.beforeId === b.id) {
        return 1
      }
      if (b.beforeId === a.id) {
        return -1
      }
    }
    // Si un seul layer a un beforeId
    else if (a.beforeId) {
      if (a.beforeId === b.id) {
        return 1
      }
    } else if (b.beforeId) {
      if (b.beforeId === a.id) {
        return -1
      }
    }

    // Vérifier si les layers correspondent à des ids du slot
    const aSlotId = slotLayersOrder.value.find(id =>
      a.id === id || a.id.startsWith(`${id}-`),
    )
    const bSlotId = slotLayersOrder.value.find(id =>
      b.id === id || b.id.startsWith(`${id}-`),
    )

    // Si les deux layers correspondent à des ids du slot, utiliser l'ordre du slot
    if (aSlotId && bSlotId) {
      return slotOrder.get(aSlotId)! - slotOrder.get(bSlotId)!
    }
    // Si un seul layer correspond à un id du slot
    else if (aSlotId) {
      return -1
    } else if (bSlotId) {
      return 1
    }

    // Si aucun des layers ne correspond à un id du slot, garder l'ordre initial
    return 0
  })
})

provide(LayerRegistryKey, {
  register: (layer: LayerInfo) => {
    layers.value.push(layer)
  },
  unregister: (id: string) => {
    layers.value = layers.value.filter(l => l.id !== id)
  },
  layers,
})

// Réorganiser les layers quand l'ordre change
watch(orderedLayers, (newLayers) => {
  const mapRef = map.value
  if (!mapRef) {
    return
  }

  newLayers.forEach((layer, index) => {
    if (index === 0) {
      return
    }
    const prevLayer = newLayers[index - 1]
    if (mapRef.getLayer(layer.id) && mapRef.getLayer(prevLayer.id)) {
      mapRef.moveLayer(layer.id, prevLayer.id)
    }
  })
})
</script>

<template>
  <slot />
</template>
