export function isString(value: any): value is string {
  return typeof value === 'string' || value instanceof String
}

export function isPromise(value: any | Promise<any>): value is Promise<any> {
  return Boolean(value && typeof value.then === 'function')
}

export async function wait(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export function newFeatureCollection<T extends GeoJSON.Geometry | null, P>(features: GeoJSON.Feature<T, P>[] = []): GeoJSON.FeatureCollection<T, P> {
  return {
    type: 'FeatureCollection',
    features,
  }
}

type NonFunctional<T> = T extends (...args: any[]) => any ? never : T

/**
 * Helper to produce an array of enum values.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T extends { [key: string]: string | number }>(enumeration: T): NonFunctional<T[keyof T]>[] {
  return Object.keys(enumeration)
    .filter(key => Number.isNaN(Number(key)))
    .map(key => enumeration[key])
    .filter((val): val is NonFunctional<T[keyof T]> => typeof val === 'number' || typeof val === 'string')
}
