<script setup>
defineProps({
  notMonitored: Boolean,
  title: {
    type: String,
    default: '',
  },
  slug: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: 'md',
    validator: validatorSizes,
  },
})

const { providerLogoBySlug } = useProviderName()
</script>

<template>
  <figure
    class="flex-none overflow-hidden rounded bg-grey-200"
    :class="{
      'h-8 w-8': size === 'md',
      'h-7 w-7': size === 'sm',
      'h-5 w-5': size === 'xs',
    }"
  >
    <img
      v-if="slug"
      class="h-full w-full bg-grey-100"
      :src="providerLogoBySlug(slug)"
      role="presentation"
      :title="title"
    >
  </figure>
</template>
