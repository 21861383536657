<script setup>
import { arrow, autoUpdate, offset, shift, useFloating } from '@floating-ui/vue'

const props = defineProps({
  is: {
    type: String,
    default: 'div',
  },
  title: {
    type: String,
    default: null,
  },
  content: {
    type: String,
    default: null,
  },
  placement: {
    type: String,
    default: 'bottom',
  },
})

const reference = ref(null)
const floating = ref(null)
const floatingArrow = ref(null)
const offsetAmount = 8

const { floatingStyles, placement, middlewareData } = useFloating(
  reference,
  floating,
  {
    whileElementsMounted: autoUpdate,
    placement: props.placement,
    middleware: [
      offset(offsetAmount),
      shift(),
      arrow({ element: floatingArrow }),
    ],
  },
)

const arrowX = computed(() => middlewareData.value.arrow?.x ?? null)
const arrowY = computed(() => middlewareData.value.arrow?.y ?? null)

const arrowStyle = computed(() => {
  const side = placement.value.split('-')[0]
  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[side]

  return {
    left: arrowX.value != null ? `${arrowX.value}px` : '',
    top: arrowY.value != null ? `${arrowY.value}px` : '',
    [staticSide]: `${-offsetAmount}px`,
  }
})

const isOpen = useElementHover(reference)
</script>

<template>
  <component
    :is="is"
    ref="reference"
  >
    <slot />

    <Teleport
      v-if="isOpen"
      to="body"
    >
      <div
        ref="floating"
        class="z-popup max-w-min rounded bg-grey-900/80 px-4 py-2 text-sm leading-3 text-white"
        :style="floatingStyles"
      >
        <div>
          <p class="whitespace-nowrap">
            <slot
              v-if="!title"
              name="title"
            />
            <template v-else>
              {{ title }}
            </template>
          </p>

          <div
            :class="{
              'mt-2': (title || $slots.title) && (content || $slots.content),
            }"
          >
            <slot
              v-if="!content"
              name="content"
            />
            <template v-else>
              {{ content }}
            </template>
          </div>
        </div>

        <div
          ref="floatingArrow"
          :style="arrowStyle"
          class="arrow absolute w-2 h-2 before:border-b-grey-900/80 before:border-r-transparent before:border-l-transparent before:left-0 before:transition-all before:visible before:border-b-8 before:border-r-8 before:border-l-8 before:absolute before:-ml-1"
          :class="{
            'bottom-full before:!border-b-0 before:!border-b-transparent before:border-t-8 before:border-t-grey-900/80':
              placement === 'top',
            '-bottom-2': placement === 'bottom',
          }"
        />
      </div>
    </Teleport>
  </component>
</template>
