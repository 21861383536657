<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclabilityData'
import type { LngLatLike } from 'maplibre-gl'

interface CyclabilityMapProps {
  zoom?: number
  center?: LngLatLike
  data: CyclabilityData
  isLoading?: boolean
}

defineOptions({
  name: 'CyclabilityMap',
})

withDefaults(defineProps<CyclabilityMapProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['on-zoom'])

const { selectedArea } = useSelectedAreaFromUrl()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('on-zoom', zoom)"
  >
    <MapLibreLayerKeepOrder>
      <CityNetwork
        v-if="data.network"
        layer-id="city-network"
        :network="data.network.roads"
        :data="data.network.data"
        :class-breaks="data.network.classbreaks"
        :metric="data.network.metric"
        :is-loading="isLoading"
      />
      <CellsGrid
        v-if="data.areas"
        layer-id="cells-grid"
        :data="data.areas.data"
        :class-breaks="data.areas.classbreaks"
        :cells-type="data.areas.type"
        :selected="selectedArea"
      />
    </MapLibreLayerKeepOrder>

    <CyclabilityMapLegends :data="data" />
  </MapLibre>
</template>
