import type { Expression } from '@/types/index.types'
import type { MaybeRef } from 'vue'
import { getSelectedColorCase, MAP_DEFAULT_COLOR } from '@/components/MapLibre/maplibreUtils'
import chroma from 'chroma-js'

type Line = Record<string, number> & {
  properties?: Record<string, number>
}

export interface ClassBreak {
  upperBound: number // =
  from: number // >=
  color: string
  label?: string
}

export type ClassBreaks = ClassBreak[]

export function makeClassBreaks(breaks: number[], colors?: string[], colorScheme: string | string[] = 'YlOrRd'): ClassBreaks {
  if (breaks.length === 0 || breaks.length < 2) {
    return []
  }

  if (breaks.length === 2) {
    return [{
      upperBound: breaks[1],
      from: breaks[0],
      color: colors ? colors[0] : chroma.scale(colorScheme as string[]).colors(1)[0],
    }]
  }

  breaks = breaks.slice().sort((a, b) => a - b)

  if (!colors) {
    colors = chroma.scale(colorScheme as string[]).mode('lch').colors(breaks.length - 1)
  }

  return breaks.slice(1).map((upperBound, index) => ({
    upperBound, // <
    from: breaks[index], // >=
    color: colors[index],
  }))
}

export function useExtractMean(data: MaybeRef<GeoJSON.FeatureCollection | Line[]>, property: MaybeRef<string> | string = 'mean'): ComputedRef<number[]> {
  if (typeof property === 'string') {
    property = ref(property)
  }

  return computed((): number[] => {
    const dataRef = get(data)

    if (!dataRef) {
      return []
    }

    if (Array.isArray(dataRef)) {
      return dataRef.map(l => Number(l[property.value] || l.properties?.[property.value] || 0))
    } else if (dataRef.type === 'FeatureCollection' && dataRef.features) {
      return dataRef.features
        .map(f => Number(f.properties?.[property.value] || 0))
        .filter(v => v !== null)
    } else {
      return []
    }
  })
}

interface ClassBreaksComposed {
  breaks: Ref<number[]>
  colors: Ref<string[]>
  classBreaks: Ref<ClassBreaks>
  getClassBreak: (value: number, list?: ClassBreaks) => ClassBreak
  getColorInRange: (value: number) => string
}

export function useClassBreaks(values: MaybeRef<number[]>, nbClasses: MaybeRef<number> = 5, colorScheme: string | string[] = 'YlOrRd'): ClassBreaksComposed {
  const breaks = ref<number[]>([])
  const colors = ref<string[]>([])
  const classBreaks = ref<ClassBreaks>([])

  if (typeof nbClasses === 'number') {
    nbClasses = ref(nbClasses)
  }

  if (Array.isArray(values)) {
    values = ref(values)
  }

  watch(
    [values, nbClasses],
    ([values, nbClasses]: [number[], number]) => {
      const setValues = [...new Set(values)]

      if (setValues.length === 0) {
        breaks.value = []
        colors.value = []
      } else if (setValues.length < nbClasses) {
        breaks.value = jenks(setValues, setValues.length - 1) as number[]
        colors.value = chroma.scale(colorScheme as string[]).colors(setValues.length - 1)
      } else {
        breaks.value = jenks(setValues, nbClasses) as number[]
        colors.value = chroma.scale(colorScheme as string[]).colors(nbClasses)
      }

      classBreaks.value = breaks.value.slice(1).map((upperBound, index) => ({
        upperBound,
        from: breaks.value[index],
        color: colors.value[index],
      }))
    },
    {
      immediate: true,
    },
  )

  function getClassBreak(value: number, list?: ClassBreaks) {
    if (!list) {
      list = classBreaks.value
    }

    return list.find(c => c.from >= value) || list[0]
  }

  function getColorInRange(value: number): string {
    if (breaks.value.length === 0) {
      return 'rgb(240,59,32)'
    }

    const index = breaks.value.findIndex(b => b >= value) - 1
    return get(colors)[index === -1 ? 0 : index]
  }

  return {
    breaks,
    colors,
    classBreaks,
    getClassBreak,
    getColorInRange,
  }
}

export function useExpressionColorFromBreaks(classBreaks: MaybeRef<ClassBreak[]>, property: MaybeRef<string> | string = 'mean', type: 'step' | 'case' = 'step'): ComputedRef<Expression> {
  if (Array.isArray(classBreaks)) {
    classBreaks = ref(classBreaks)
  }

  if (typeof property === 'string') {
    property = ref(property)
  }

  return computed(() => {
    const classBreaksRef = classBreaks.value

    if (classBreaksRef && classBreaksRef.length > 0) {
      const expression: Expression = []
      const get = ['get', property.value]
      const lastColor = classBreaksRef[classBreaksRef.length - 1].color || '#20757C'

      if (type === 'step') {
        expression.push(
          'step',
          get,
          lastColor,
        )
        classBreaksRef.forEach((classBreak) => {
          expression.push(classBreak.from || 0, classBreak.color || 'transparent')
        })
      } else {
        expression.push('case')
        classBreaksRef.forEach((classBreak) => {
          expression.push(['<=', get, classBreak.from || 0], classBreak.color || 'transparent')
        })
        expression.push(lastColor)
      }

      return getSelectedColorCase(expression)
    } else {
      return getSelectedColorCase(MAP_DEFAULT_COLOR)
    }
  })
}
