export enum CyclabilityNetworkRoadTypes {
  Independant_cycle_and_pedestrian_track,
  Physically_separated_cycle_track,
  Dedicated_track,
  Dedicated_lanes,
  Pedestrian_track_with_cycling_allowed,
  Pedestrian_street,
  Living_street,
  Limited_access_roads,
  Shared_bus_lanes,
  Medium_and_low_speed_road,
  Medium_speed_roads,
  High_speed_roads,
  In_construction,
  Others,
}

export enum RoadCyclingInfraTypes {
  Independant_cycle_and_pedestrian_track = 0,
  Physically_separated_cycle_track = 1,
  Dedicated_track = 2,
  Dedicated_lanes = 3,
  Pedestrian_track_with_cycling_allowed = 4,
  Pedestrian_street = 5,
  Living_street = 6,
  Limited_access_roads = 7,
  Shared_bus_lanes = 8,
}

export type CyclabilityNetworkRoadType = keyof typeof CyclabilityNetworkRoadTypes
export type RoadCyclingInfraType = keyof typeof RoadCyclingInfraTypes
