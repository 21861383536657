<script setup lang="ts">
defineOptions({
  name: 'CyclabilityPictureDialog',
})

const props = defineProps<{
  modelValue?: string
  picture?: string
}>()

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()

const isOpen = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})
</script>

<template>
  <DModal
    v-if="isOpen"
    size="full"
    @close="() => (isOpen = undefined)"
  >
    <template #title>
      {{ t('Street view') }}
    </template>

    <div
      v-if="picture"
      class="relative"
    >
      <img
        :src="picture"
        class="max-h-[80vh] w-auto object-contain mx-auto"
        alt="Street view"
      >
    </div>
    <div
      v-else
      class="p-4 text-center text-gray-500"
    >
      {{ $t('No picture available') }}
    </div>
  </DModal>
</template>
