<script setup lang="ts">
import type { CyclabilityDataLayer } from '@/composables/cyclabilityData'

defineOptions({
  name: 'CyclabilityMapLegendCollapsable',
})

const props = defineProps<CyclabilityMapSegmentsProps>()
const emit = defineEmits(['toggle-expanded'])

interface CyclabilityMapSegmentsProps {
  layer: 'areas' | 'roads' | 'network'
  layerData: CyclabilityDataLayer
  expanded?: boolean
}

const { translateIfExists, translationExists } = useLabelTranslation()

function hasTranslation(field?: string, type?: 'title' | 'descriptions') {
  return translationExists(`cyclability.data.${props.layer}${type ? `_${type}` : ''}${field ? `.${field}` : ''}`)
}

function translateData(field?: string, type?: 'title' | 'descriptions') {
  return translateIfExists(`cyclability.data.${props.layer}${type ? `_${type}` : ''}${field ? `.${field}` : ''}`, field)
}
</script>

<template>
  <div>
    <p
      class="mb-2 cursor-pointer"
      @click="() => emit('toggle-expanded', layer)"
    >
      <DIcon
        :name="expanded ? 'chevron-up' : 'chevron-down'"
        size="sm"
      />
      {{ translateData(undefined, 'title') }}<br>
      <small class="text-grey-700">{{ translateData(layerData.metric) }}</small>
      <DTooltip
        is="span"
        v-if="!expanded && hasTranslation(layerData.metric, 'descriptions')"
        placement="top"
      >
        <DIcon
          path="help-circle"
          size="sm"
          class="text-blue-500"
        />
        <template #content>
          <p class="w-40">
            {{ translateData(layerData.metric, 'descriptions') }}
          </p>
        </template>
      </DTooltip>
    </p>

    <p
      v-if="expanded && hasTranslation(layerData.metric, 'descriptions')"
      class="text-grey-400 text-ellipsis max-w-full mb-2"
    >
      <small>{{ translateData(layerData.metric, 'descriptions') }}</small>
    </p>

    <MapLegend
      :class-breaks="layerData.classbreaks"
      :inline="!expanded"
    >
      <template
        v-if="layerData.labels"
        #label="{ index }"
      >
        {{ layerData.labels?.[index] }}
      </template>
    </MapLegend>
  </div>
</template>
