import type { Expression } from '@/types/index.types'
import type { Map } from 'maplibre-gl'

import type { InjectionKey, Ref } from 'vue'

export const MAP_SELECTED_COLOR = '#4F74EF'
export const MAP_DEFAULT_COLOR = '#C1CCCD'

export interface MapLibreConfig {
  i18n?: boolean
  style: string
}

export const defaultMapLibreConfig: MapLibreConfig = {
  i18n: false,
  style: 'mapbox://styles/mapbox/streets-v11',
}

type ExpressionPart = Expression | string

export function getSelectedColorCase(cases?: ExpressionPart): Expression {
  const colorCase = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    MAP_SELECTED_COLOR,
  ] as Expression

  if (cases) {
    colorCase.push(cases)
  } else {
    colorCase.push(MAP_DEFAULT_COLOR)
  }

  return colorCase
}

export function useMap() {
  const map = inject<Ref<Map | null>>('map', ref(null))

  // function onMove(callback: (map: Map) => void) {
  //   if (map.value) {
  //     map.value.on('move', () => {
  //       callback(map.value!)
  //     })
  //   }
  // }

  // function onZoom(callback: (map: Map) => void) {
  //   if (map.value) {
  //     map.value.on('zoom', () => {
  //       callback(map.value!)
  //     })
  //   }
  // }

  // onUnmounted(() => {
  //   if (map.value) {
  //     map.value.off('move')
  //     map.value.off('zoom')
  //   }
  // })

  return {
    map,
    // onMove,
    // onZoom,
  }
}

export interface LayerInfo {
  id: string
  order: number
  beforeId?: string
}

export const LayerRegistryKey = Symbol('LayerRegistry') as InjectionKey<{
  register: (layer: LayerInfo) => void
  unregister: (id: string) => void
  layers: Ref<LayerInfo[]>
}>
