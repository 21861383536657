<script setup lang="ts">
import type { ClassBreaks } from '@/composables/classBreaks'
import type { NetorkLineID, Network, NetworkData, RoadLines } from '@/composables/cyclabilityNetwork'
import { ROAD_TYPES } from '@cd/screen-data'

interface NetwotkProperties {
  infra_type_key: number
  mean?: string | number | null
  id?: string
}

type RoadFeatures = GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString, NetwotkProperties>

defineOptions({
  name: 'CityNetwork',
})

const props = withDefaults(defineProps<{
  layerId?: string
  network: Network
  data?: NetworkData
  classBreaks: ClassBreaks
  labels?: string[]
  metric?: string
  isLoading?: boolean
}>(), {
  layerId: 'city-network',
  metric: 'road',
})

const emit = defineEmits(['ready'])

const types = ROAD_TYPES
const { classBreaks } = toRefs(props)
const geojsonChunks = shallowRef<Map<string, GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.MultiLineString, NetwotkProperties>>>(new Map())
const realMetric = computed(() => props.metric === 'road' ? 'infra_type_key' : 'mean')
const paintColor = useExpressionColorFromBreaks(classBreaks, realMetric, 'case')
const chunksUpdating = ref(false)

const geometriesFilter = computed(() => {
  return ['==', ['typeof', ['get', realMetric.value]], 'number']
})

async function makeGeojsonChunks(network: Network, data?: NetworkData) {
  chunksUpdating.value = true
  const chunks = new Map<string, RoadFeatures[]>()

  await asyncExecByFrame(network, async (networkType: RoadLines) => {
    if (!networkType.lines?.length) {
      return
    }

    const infraTypeKey = types.indexOf(networkType.roadType)

    // Group lines by their mean value
    const linesByMean = new Map<string | number | null, GeoJSON.Position[][]>()

    for (let j = 0; j < networkType.lines.length; j++) {
      const lineId = `${networkType.index}_${j}` as NetorkLineID
      const meanValue = data?.get(lineId) ?? null

      if (!linesByMean.has(meanValue)) {
        linesByMean.set(meanValue, [])
      }
      const lines = linesByMean.get(meanValue)
      if (lines) {
        lines.push(networkType.lines[j])
      }
    }

    const features: RoadFeatures[] = []

    // Create one feature per mean value with a MultiLineString geometry
    linesByMean.forEach((lines, meanValue) => {
      features.push({
        type: 'Feature',
        properties: {
          infra_type_key: infraTypeKey,
          mean: meanValue,
        },
        geometry: {
          type: 'MultiLineString',
          coordinates: lines,
        },
      })
    })

    chunks.set(networkType.roadType, features)

    // Update the chunks already processed
    geojsonChunks.value = new Map(
      Array.from(chunks.entries()).map(([key, features]) => [
        key,
        newFeatureCollection<GeoJSON.LineString | GeoJSON.MultiLineString, NetwotkProperties>(features),
      ]),
    )

    await nextTick()
    chunksUpdating.value = false
  }, null)
}

watch([() => props.network, () => props.data], async ([network, data]) => {
  if (network && data) {
    await makeGeojsonChunks(network, data)
    emit('ready')
  }
}, { immediate: true })
</script>

<template>
  <template v-if="geojsonChunks">
    <MapLibreSourceGeojson
      v-for="([roadType, chunk], index) in geojsonChunks"
      :id="`${layerId}-${roadType}`"
      :key="roadType"
      :data="chunk"
      :geojson-options="{
        buffer: 64,
        tolerance: 0.375,
      }"
      :layer-props="{
        type: 'line',
        beforeId: index ? `${layerId}-${Array.from(geojsonChunks.keys())[0]}` : undefined,
        paint: {
          'line-width': 2,
          'line-opacity': 0.73,
          'line-color': paintColor || '#576067',
        },
        filter: geometriesFilter,
      }"
    />
  </template>

  <MapLibreControl
    v-if="chunksUpdating"
    position="top-right"
  >
    <DLoader />
  </MapLibreControl>
</template>
