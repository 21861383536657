<script setup lang="ts">
import type { ClassBreaks } from '@/composables/classBreaks'
import type { AirQualityMetric, RoadCycleRapMetric, RoadMetric } from '@/composables/cyclabilityRoads'

interface CitySegmentsProps {
  geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>
  metric?: RoadMetric
  selected?: string[]
  classBreaks: ClassBreaks
  labels?: string[]
  layerId?: string
  outline?: {
    classbreaks: ClassBreaks
    labels: string[]
    metric: string
  }
}

defineOptions({
  name: 'CitySegments',
})

const props = withDefaults(defineProps<CitySegmentsProps>(), {
  geojson: () => newFeatureCollection(),
  metric: 'score',
  selected: () => [],
  layerId: 'city-segments',
})

const emit = defineEmits(['ready'])

const { geojson, metric, classBreaks, outline } = toRefs(props)
const colorBreaks = useExpressionColorFromBreaks(classBreaks, metric, 'case')
const { translateIfExists } = useLabelTranslation()

const geometriesFilter = computed(() => {
  return ['==', ['typeof', ['get', props.metric]], 'number']
})

const paintExpression = computed(() => {
  return {
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      PALETTE_COLORS[0],
      colorBreaks.value,
    ],
    'line-width': 12,
  }
})

const outlinePaintExpression = computed(() => {
  const outlineValue = outline.value

  if (!outlineValue) {
    return {
      'line-color': 'black',
      'line-opacity': 0.66,
      'line-width': 16,
    }
  }

  const outlineColorBreaks = useExpressionColorFromBreaks(
    outlineValue.classbreaks,
    outlineValue.metric,
    'case',
  )

  return {
    'line-color': outlineColorBreaks.value,
    'line-opacity': 1,
    'line-width': 18,
  }
})

const metricLabel = computed(() => `${translateIfExists(`cyclability.data.roads.${metric.value}`, metric.value)} :`)
</script>

<template>
  <MapLibrePopup
    :offset="[0, -5]"
    :layer-id="layerId"
  >
    <template #element="{ feature }">
      <div class="flex w-28 flex-col whitespace-normal text-xs">
        <p v-if="cycleRapMetrics.includes(metric as RoadCycleRapMetric) || airQualityMetrics.includes(metric as AirQualityMetric)">
          <span class="text-nowrap">{{ metricLabel }}</span><br>
          <span class="font-bold">{{ labels?.[feature?.properties?.[metric] - 1] }}</span>
        </p>
        <p v-else-if="metric === 'pothole'">
          <span class="text-nowrap">{{ metricLabel }}</span><br>
          <span class="font-bold">{{ labels?.[feature?.properties?.[metric]] }}</span>
        </p>
        <p v-else>
          <span class="text-nowrap">{{ metricLabel }}</span><br>
          <span class="font-bold">{{ feature?.properties?.[metric] }}</span><br>
          <span
            v-if="outline"
            class="text-xs text-gray-500"
          >
            <span class="text-nowrap">{{ translateIfExists(`cyclability.data.roads.${outline.metric}`, outline.metric) }} :</span><br>
            <span class="font-bold">{{ outline.labels?.[feature?.properties?.[outline.metric] - 1] }}</span>
          </span>
        </p>
      </div>
    </template>
  </MapLibrePopup>

  <MapLibreSourceGeojson
    :id="layerId"
    :layer-props="[{
      id: `${layerId}-border`,
      beforeId: layerId,
      type: 'line',
      paint: outlinePaintExpression,
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      filter: geometriesFilter,
    }, {
      type: 'line',
      paint: paintExpression,
      layout: {
        'line-cap': 'round', // round | butt | square
        'line-join': 'miter', // round | bevel | miter
      },
      filter: geometriesFilter,
    }]"
    :data="geojson"
    :geojson-options="{
      promoteId: 'name',
    }"
    :selected-features="selected || []"
    @ready="() => emit('ready')"
  />
</template>
