<script setup>
import { useFuse } from '@vueuse/integrations/useFuse'

const router = useRouter()
const route = useRoute()
const { isFreemium } = storeToRefs(useUserStore())
const providersStore = useProvidersStore()
const citiesStore = useCitiesStore()
const navigationStore = useNavigationStore()
const { getPref } = usePreferences()
const { isFavorite } = useFavorite()

const { isMobile } = useMobile()

const showBlockedModal = ref(false)
const searchInput = ref()
const searchResult = ref()
const selected = ref(0)
const search = ref('')
const data = computed(() => {
  return [...citiesStore.cities, ...providersStore.providers]
})

const { t } = useI18n()

const { results } = useFuse(search, data, {
  fuseOptions: {
    includeScore: true,
    keys: [
      'name',
      'slug',
      'nameLocalized',
      { name: 'countryLocalized', weight: 4 },
    ],
  },
  resultLimit: 10,
})

const { focused } = useFocusWithin(searchInput)
const { onDown, onEnter, onUp } = useInputShortcut(searchInput)
const { isOpen, floatingStyles, width } = useOpenAndFloat(
  searchInput,
  searchResult,
)

const navigationItems = computed(() => {
  const visited = getPref('visited') || []

  if (!get(search).length) {
    return visited
      .map(({ city, provider }) =>
        city ? citiesStore.getCity(city) : providersStore.getProvider(provider),
      )
      .filter(v => v !== null)
  } else {
    return (
      get(results)
        .map(i => i.item)
        .sort((a, b) => {
          if (isItemFavorite(a) && !isItemFavorite(b)) {
            return -1
          } else if (!isItemFavorite(a) && isItemFavorite(b)) {
            return 1
          }

          return 0
        }) || []
    )
  }
})

// watching if the search is open on mobile and focus the input
watch(
  [() => navigationStore.searchIsOpen, searchInput],
  async ([isOpenStore, input]) => {
    if (isOpenStore && input) {
      await nextTick()
      input.$refs.input.focus()
    }
  },
  { immediate: true },
)

// reset selected when results change
whenever(results, () => set(selected, 0), { immediate: true })

whenever(focused, (focused) => {
  if (focused) {
    set(isOpen, true)
  }
})

onDown(() => {
  if (selected.value < results.value.length - 1) {
    selected.value++
  }
})

onUp(() => {
  if (selected.value > 0) {
    selected.value--
  }
})

onEnter(() => {
  const result = results.value[selected.value] || null

  if (result) {
    const item = result.item

    if (get(isFreemium) && !isCity(item)) {
      set(showBlockedModal, true)
    } else {
      const routeRaw = getRoute(item)

      if (routeRaw) {
        router.push(routeRaw)
      }
    }

    set(search, '')
  }
})

function isCity(item) {
  return item.__typename === 'City'
}

function getRoute(item) {
  const lastMode = route.params.mode
  const params = isCity(item)
    ? { params: { citySlug: item.name, mode: lastMode || VEHICLE_TYPE_ALL } }
    : { params: { provider: item.slug, mode: lastMode || VEHICLE_TYPE_ALL } }

  if (router.resolve(params).path !== route.path) {
    return params
  } else {
    if (isCity(item)) {
      return item.unlocked
        ? { name: 'CityMode', ...params }
        : { name: 'SubscribePlans' }
    } else {
      if (!get(isFreemium)) {
        return item.unlocked && item.availableInCD
          ? { name: 'ProviderMode', ...params }
          : { name: 'ProviderFootprint', ...params }
      } else {
        return null
      }
    }
  }
}

function isItemFavorite(item) {
  return isCity(item)
    ? isFavorite(item.name, 'city')
    : isFavorite(item.slug, 'provider')
}
</script>

<template>
  <div
    class="gradient fixed top-16 inset-x-0 z-navbar w-full md:relative md:top-auto md:inset-x-auto md:bg-none text-base font-body"
    :class="{ 'hidden md:block': !navigationStore.searchIsOpen }"
  >
    <DInputText
      ref="searchInput"
      v-model="search"
      class="shadow-2xl"
      :placeholder="t('placeholder_quick_access')"
      type="search"
      :size="isMobile ? 'lg' : 'sm'"
    >
      <template #left>
        <DIcon
          path="search"
          :size="isMobile ? 'md' : 'sm'"
          :class="{ '!p-2': !isMobile, '!px-2 !py-3 mt-1': isMobile }"
        />
      </template>
    </DInputText>

    <Teleport to="body">
      <div
        v-if="isOpen"
        ref="searchResult"
        :style="[floatingStyles, { width: `${width}px` }]"
        class="group z-overall md:z-menu relative w-full divide-y bg-white shadow-lg md:absolute"
      >
        <NavigationSearchItem
          v-for="(item, index) in navigationItems"
          :key="item.slug || item.name"
          :slug="item.slug"
          :name="item.nameLocalized || item.name"
          :unlocked="item.unlocked"
          :type="isCity(item) ? 'city' : 'provider'"
          :country="
            isCity(item)
              ? {
                countryCode: item.countryCode,
                countryLocalized: item.countryLocalized,
              }
              : null
          "
          :is-selected="selected === index"
          :route="getRoute(item)"
          @click="
            () => {
              if (isFreemium && !isCity(item)) {
                showBlockedModal = true
              }
              else {
                search = ''
              }
            }
          "
          @mouse-enter="() => (selected = index)"
        />
      </div>
    </Teleport>

    <FreemiumOperatorBlockedModal
      v-if="showBlockedModal"
      @close="() => (showBlockedModal = false)"
    />
  </div>
</template>
