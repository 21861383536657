import type { ClassBreaks } from '@/composables/classBreaks'
import type { EstimatedSerie, MaybeEstimatedSerie, NotEstimatedSerie } from '@/types/charts.types'
import type { CyclabilityNetworkRoadType } from '@/types/cyclability.types'
import type { RequireAtLeastOne } from '@/types/index.types'

import type { ModeAndOther } from '@/types/modes.types'
import chroma from 'chroma-js'
import pattern from 'patternomaly'

export type Color = `#${string}` | `rgba(${number},${number},${number},${number})` | `rgb(${number},${number},${number})` | string
export type Palette = Color[]
export type EnumPalette<T extends string = string> = Record<T, Color>
export type ColorOrPattern = Color | CanvasPattern

export type PaletteVehicles = EnumPalette<ModeAndOther>

export const DEFAULT_COLOR = '#EEEEEE'

export const PALETTE_COLORS: Palette = [
  '#38BCB4',
  '#4CB8E9',
  '#3990CD',
  '#40449D',
  '#9E3999',
  '#DC3C78',
  '#F57548',
  '#FDF04B',
  '#7BD293',
  '#408B76',
  '#408B76',
  '#3C73A2',
  '#55306B',
  '#80382E',
  '#957D2A',
  '#303D4E',
  '#606D7C',
  '#49170F',
  '#F6E6D5',
  '#CDB6DB',
  '#1E2832',
  '#9B9B9B',
  '#D7D7D7',
  '#FF004C',
]

export const PALETTE_COLORS_30: Palette = [
  '#A24F5B',
  '#5EC353',
  '#AC62D6',
  '#9AB635',
  '#6165D0',
  '#C5B03C',
  '#CB4DB0',
  '#478E35',
  '#E64588',
  '#5DC48B',
  '#CE3F4C',
  '#42C3C2',
  '#CE4929',
  '#52A3D8',
  '#DE972F',
  '#526CAF',
  '#D9783C',
  '#A095E0',
  '#656F1A',
  '#92508E',
  '#9CB36A',
  '#BA3E6D',
  '#489D76',
  '#DD88BD',
  '#2D724D',
  '#E18178',
  '#5E7036',
  '#D6A369',
  '#975A2C',
  '#987E31',
]

export const PALETTE_COLORS_RATING: Palette = [
  '#FF6F31',
  '#FF9F02',
  '#FFCF02',
  '#9ACE6A',
  '#57BB8A',
]

export const PALETTE_COLORS_CYCLERAP: Palette = [
  '#87C424',
  '#FFCC1A',
  '#FF5B1A',
  '#CD1AFF',
]

export const PALETTE_COLORS_NETWORK_CLASS: EnumPalette<CyclabilityNetworkRoadType> = {
  Independant_cycle_and_pedestrian_track: '#3A6EA5',
  Physically_separated_cycle_track: '#4CAF50',
  Dedicated_track: '#66BB6A',
  Dedicated_lanes: '#8BC34A',
  Pedestrian_track_with_cycling_allowed: '#C0CA33',
  Pedestrian_street: '#FFEB3B',
  Living_street: '#FFC107',
  Limited_access_roads: '#FFB300',
  Shared_bus_lanes: '#FF9800',
  Medium_and_low_speed_road: '#FB8C00',
  Medium_speed_roads: '#F4511E',
  High_speed_roads: '#E53935',
  In_construction: '#DBDBDB',
  Others: '#20757c',
}

export const PALETTE_COLORS_VEHICLES: PaletteVehicles = {
  bike: '#4a72f6',
  scooter: '#35b3be',
  moped: '#f6b246',
  car: '#f60f51',
  other: '#20757c',
}

export const PALETTE_COLORS_CAR_PROPULSION: Palette = [
  '#FBEF6A', // CAR_PROPULSION_ELECTRIC,
  '#6D8A8A', // CAR_PROPULSION_COMBUSTION,
  '#8FCF98', // CAR_PROPULSION_HYBRID,
]

export const PALETTE_COLORS_WEEK: EnumPalette = {
  weekday: '#20757C',
  weekend: '#8DB9BC',
}

export const PALETTE_COLORS_CAR_SIZE = chroma
  .scale([chroma('#B52B44').luminance(0.8).hex(), '#B52B44'])
  .colors(CAR_SIZES.length) as Palette

export const lighten = function (hex: string, factor = 0.5): Color {
  return chroma(hex).brighten(factor).hex()
}

export function getPaletteVehicleTypeBySeries(series: Array<{ name: ModeAndOther }>): Palette {
  return series.reduce((acc: Palette, serie) => {
    const vehicleType = getVehicleType(serie.name) as ModeAndOther
    acc.push(PALETTE_COLORS_VEHICLES[vehicleType])
    return acc
  }, [])
}

function getIfUnknownColor(type: string, getColor: (c: string) => string) {
  if (type === UNKNOWN) {
    return '#000000'
  } else {
    return getColor(type)
  }
}

export function getColorByCarSize(size: string) {
  return getIfUnknownColor(
    size,
    t => PALETTE_COLORS_CAR_SIZE[CAR_SIZES.indexOf(t)],
  )
}

export function getColorByCarPropulsion(propulsion: string) {
  return getIfUnknownColor(
    propulsion,
    t => PALETTE_COLORS_CAR_PROPULSION[CAR_PROPULSIONS.indexOf(t)],
  )
}

export function getStatusColor(status: string) {
  switch (status) {
    case 'ACTIVE':
      return 'rgba(0, 192, 125, 0.3)'
    case UNKNOWN:
      return 'rgba(255, 200, 81, 0.3)'
    case 'PAUSED':
      return 'rgba(69, 94, 239, 0.3)'
    case 'STOPPED':
      return 'rgb(136, 142, 159, 0.3)'
    default:
      return 'red'
  }
}

export function getColorByIndex(index: number = 0, colors?: Palette): Color {
  const palette = colors || PALETTE_COLORS
  const modulo = index % palette.length
  const factor = Math.floor(index / palette.length)

  return factor > 0
    ? lighten(palette[modulo], 0.5 * factor)
    : palette[index] || DEFAULT_COLOR
}

export function getColorFromSerie(serieWithColor: RequireAtLeastOne<NotEstimatedSerie, 'color'>, dataIndex?: number, colors?: Palette): Color
export function getColorFromSerie(serieWithoutColor: Omit<RequireAtLeastOne<NotEstimatedSerie, 'data'>, 'color'>, dataIndex?: number, colors?: Palette): Color[]
export function getColorFromSerie(serieEstimated: Omit<EstimatedSerie, 'estimated'>, dataIndex?: number, colors?: Palette): CanvasPattern[]
export function getColorFromSerie(serieEstimatedProp: Extract<EstimatedSerie, 'estimated'>, dataIndex?: number, colors?: Palette): CanvasPattern
export function getColorFromSerie(d: MaybeEstimatedSerie, dataIndex?: number, colors?: Palette): ColorOrPattern | ColorOrPattern[] {
  const color = getColorByIndex(dataIndex, colors)

  if (d.color) {
    return d.color
  }

  if ('estimated' in d && d.estimated) {
    return pattern.draw('diagonal-right-left', color)
  }

  if (d.data) {
    return d.data.map(e =>
      Array.isArray(e) && e[2]
        ? pattern.draw('diagonal-right-left', color)
        : color,
    )
  }

  return color
}

interface Status {
  code: string
}

export function getDataColorByStatus({ status }: { status?: Status | Status[] }): ColorOrPattern {
  if (!status) {
    return 'rgba(0,0,0,0)'
  }

  if (Array.isArray(status) && status.length > 1) {
    const canvasPattern = document.createElement('canvas')
    const contextPattern = canvasPattern.getContext('2d')

    const statusColors = status.map((c) => {
      return getStatusColor(c.code)
    })

    const size = 10 * statusColors.length

    if (contextPattern) {
      canvasPattern.width = size
      canvasPattern.height = size

      const lineWidth = Math.hypot(size, size) / statusColors.length / 2

      contextPattern.rotate((45 * Math.PI) / 180)
      contextPattern.translate(lineWidth * -1, -1000)

      let c = 1

      for (let p = lineWidth / 2; p < 2000; p += lineWidth) {
        contextPattern.beginPath()
        contextPattern.strokeStyle = statusColors[c]

        c++
        if (c >= statusColors.length) {
          c = 0
        }

        contextPattern.lineWidth = lineWidth
        contextPattern.lineJoin = 'miter' // Fix: Change 'square' to 'miter'
        contextPattern.lineCap = 'square'

        contextPattern.moveTo(p, 0)
        contextPattern.lineTo(p, 2000)

        contextPattern.stroke()
        contextPattern.closePath()
      }

      return contextPattern.createPattern(canvasPattern, 'repeat') || 'rgba(0,0,0,0)'
    }
  } else {
    if (Array.isArray(status) && status.length === 1) {
      return getStatusColor(status[0].code)
    } else if (Array.isArray(status) && !status.length) {
      return 'rgba(0,0,0,0)'
    } else if (status && 'code' in status) {
      return getStatusColor(status.code)
    }
  }
  return 'rgba(0,0,0,0)'
}

export function getColorFromClassBreak(value: number, classBreaks: ClassBreaks, colors?: Palette) {
  if (classBreaks.length === 0) {
    return 'rgb(240,59,32)'
  }

  const index = classBreaks.findIndex(b => value >= b.from && value < b.upperBound)

  if (!colors) {
    const { color } = classBreaks[index] || {}
    return color
  } else {
    return colors[index]
  }
}

export default {
  DEFAULT_COLOR,
  PALETTE_COLORS,
  PALETTE_COLORS_30,
  getColorFromSerie,
  getStatusColor,
  getDataColorByStatus,
  getColorFromClassBreak,
}
