import type { CellsData } from '@/types/maps.types'

export const useCyclabilityAreas = createSharedComposable((citySlug: Ref<string | null>) => {
  const { areaActiveFile, urlToFetch, areasMetric, availableAreasMetrics } = useCyclability(citySlug)

  const loading = ref(0)
  const areas = shallowRef<string[]>([])
  const areasData = shallowRef<CellsData>(new Map())

  const areasMetricActiveFile = computed(() => {
    return areaActiveFile.value?.metrics.find(m => m.metric === areasMetric.value)
  })

  async function getCyclabilityAreas() {
    loading.value++

    const url = urlToFetch(areaActiveFile.value?.filePath || '')
    const data = await fetchParquet(url)
    const key = Object.keys(data[0])[0]

    areas.value = data.map(record => record[key])

    getCyclabilityAreasData()
    loading.value--
  }

  async function getCyclabilityAreasData() {
    loading.value++

    try {
      const url = urlToFetch(areasMetricActiveFile.value?.filePath || '')
      const raw = await fetchParquet(url)
      const key = Object.keys(raw[0])[0]
      const data = raw.map(record => record[key] || null)
      const map = new Map()

      for (let i = 0; i < areas.value.length; i++) {
        if (data[i] !== null && data[i] !== undefined) {
          // @ts-expect-error because the type definition is wrong
          map.set(areas.value[i].toString(16), data[i])
        }
      }

      areasData.value = map
    } catch {
      areasData.value = new Map()
    }

    loading.value--
  }

  whenever(areaActiveFile, getCyclabilityAreas, { immediate: true })
  whenever(areasMetricActiveFile, getCyclabilityAreasData)
  whenever(availableAreasMetrics, (availableAreasMetrics) => {
    const lastValue = areasMetric.value

    if (lastValue && availableAreasMetrics.map(m => m.name).includes(lastValue as string)) {
      areasMetric.value = lastValue
    } else {
      areasMetric.value = availableAreasMetrics[0]?.name || undefined
    }
  }, { immediate: true })

  return {
    loading: computed(() => loading.value > 0),
    areasData,
    areasMetricActiveFile,
    getCyclabilityAreas,
    getCyclabilityAreasData,
  }
})
