<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclabilityData'
import type { RoadMetric } from '@/composables/cyclabilityRoads'
import type { LngLatLike } from 'maplibre-gl'

defineOptions({
  name: 'CyclabilityMapSegments',
})

withDefaults(defineProps<CyclabilityMapSegmentsProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['onZoom'])

interface CyclabilityMapSegmentsProps {
  zoom?: number
  center?: LngLatLike
  data: CyclabilityData
  legendTitle?: string
  isLoading?: boolean
}

const { selectedArea } = useSelectedAreaFromUrl()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('onZoom', zoom)"
  >
    <MapLibreLayerKeepOrder>
      <CitySegments
        v-if="data?.roads"
        layer-id="city-segments"
        :geojson="data.roads.geojson"
        :metric="data.roads.metric as RoadMetric"
        :class-breaks="data.roads.classbreaks"
        :labels="data.roads.labels"
        :outline="data.roads.outline"
        :selected="selectedArea"
      />

      <CityNetwork
        v-if="data?.network"
        layer-id="city-network"
        :network="data.network.roads"
        :data="data.network.data"
        :class-breaks="data.network.classbreaks"
        :metric="data.network.metric"
        :is-loading="isLoading"
      />

      <CellsGrid
        v-if="data?.areas"
        layer-id="cells-grid"
        :data="data.areas.data"
        :class-breaks="data.areas.classbreaks"
        :cells-type="data.areas.type"
      />
    </MapLibreLayerKeepOrder>

    <MapSelectArea
      :layers-ids="['city-segments']"
      :show-button="false"
    />

    <CyclabilityMapLegends :data="data" />
  </MapLibre>

  <CyclabilitySidebarSegment :data="data" />
</template>
