<script setup lang="ts">
import type { DateRange } from '@/stores/filters'

defineOptions({
  name: 'CityCyclabilityPassagesHistoryMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())
const { writeCache } = useWriteCacheForMetric('cityCyclabilityPassagesHistory')

const dateRange = {
  start: new Date(formatDate('2024-04-29', DATE_FORMAT)),
  end: new Date(formatDate('2024-06-29', DATE_FORMAT)),
}

const { translateDate } = useDateTranslation()

function translateRange(range: DateRange) {
  return {
    start: translateDate(range.start, DATES_FORMATS.FULL),
    end: translateDate(range.end, DATES_FORMATS.FULL),
  }
}

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
    dateRange,
  }, {
    series: [
      {
        name: 'all',
        label: 'All',
        color: '#33488a',
        data: [
          // ['2023-04-21', 8278],
          // ['2023-04-22', 8180],
          // ['2023-04-23', 7342],
          // ['2023-04-24', 9445],
          // ['2023-04-25', 9731],
          // ['2023-04-26', 9943],
          // ['2023-04-27', 10358],
          // ['2023-04-28', 9829],
          // ['2023-04-29', 8473],
          // ['2023-04-30', 7505],
          // ['2023-05-01', 8010],
          // ['2023-05-02', 9972],
          // ['2023-05-03', 10407],
          // ['2023-05-04', 10948],
          // ['2023-05-05', 10661],
          // ['2023-05-06', 9361],
          // ['2023-05-07', 7484],
          // ['2023-05-08', 10449],
          // ['2023-05-09', 10692],
          // ['2023-05-10', 9806],
          // ['2023-05-11', 8738],
          // ['2023-05-12', 6917],
          // ['2023-05-13', 6244],
          // ['2023-05-14', 7446],
          // ['2023-05-15', 9092],
          // ['2023-05-16', 10265],
          // ['2023-05-17', 10351],
          // ['2023-05-18', 10098],
          // ['2023-05-19', 7879],
          // ['2023-05-20', 7558],
          // ['2023-05-21', 7468],
          // ['2023-05-22', 8262],
          // ['2023-05-23', 1300],
          // ['2023-05-25', 4200],
          // ['2023-05-26', 9776],
          // ['2023-05-27', 9507],
          // ['2023-05-28', 9876],
          // ['2023-05-29', 10789],
          // ['2023-05-30', 11850],
          // ['2023-05-31', 10270],
          // ['2023-06-01', 9774],
          // ['2023-06-02', 10676],
          // ['2023-06-03', 8493],
          // ['2023-06-04', 8906],
          // ['2023-06-05', 10390],
          // ['2023-06-06', 11439],
          // ['2023-06-07', 9682],
          // ['2023-06-08', 13085],
          // ['2023-06-09', 11453],
          // ['2023-06-10', 10580],
          // ['2023-06-11', 9598],
          // ['2023-06-12', 10926],
          // ['2023-06-13', 10163],
          // ['2023-06-14', 9798],
          // ['2023-06-15', 12316],
          // ['2023-06-16', 11264],
          // ['2023-06-17', 10959],
          // ['2023-06-18', 8677],
          // ['2023-06-19', 10314],
          // ['2023-06-20', 12271],
          // ['2023-06-21', 11819],
          // ['2023-06-22', 12421],
          // ['2023-06-23', 10268],
          // ['2023-06-24', 8250],
          // ['2023-06-25', 9758],
          // ['2023-06-26', 11345],
          // ['2023-06-27', 11955],
          // ['2023-06-28', 11242],
          // ['2023-06-29', 10744],
          // ['2023-06-30', 10508],
          // ['2023-07-01', 9246],
          // ['2023-07-02', 10465],
          // ['2023-07-03', 11293],
          // ['2023-07-04', 12652],
          // ['2023-07-05', 11196],
          // ['2023-07-06', 12452],
          // ['2023-07-07', 11119],
          // ['2023-07-08', 10356],
          // ['2023-07-09', 9811],
          // ['2023-07-10', 9722],
          // ['2023-07-11', 11246],
          // ['2023-07-12', 11169],
          // ['2023-07-13', 11321],
          // ['2023-07-14', 10491],
          // ['2023-07-15', 8284],
          // ['2023-07-16', 8524],
          // ['2023-07-17', 10321],
          // ['2023-07-18', 11057],
          // ['2023-07-19', 10619],
          // ['2023-07-20', 10489],
          // ['2023-07-21', 9972],
          // ['2023-07-22', 7522],
          // ['2023-07-23', 8010],
          // ['2023-07-24', 9555],
          // ['2023-07-25', 10489],
          // ['2023-07-26', 10087],
          // ['2023-07-27', 12095],
          // ['2023-07-28', 10112],
          // ['2023-07-29', 8091],
          // ['2023-07-30', 7896],
          // ['2023-07-31', 7873],
          // ['2023-08-01', 9886],
          // ['2023-08-02', 8165],
          // ['2023-08-03', 8555],
          // ['2023-08-04', 9371],
          // ['2023-08-05', 8199],
          // ['2023-08-06', 7927],
          // ['2023-08-07', 10679],
          // ['2023-08-08', 11102],
          // ['2023-08-09', 9436],
          // ['2023-08-10', 8423],
          // ['2023-08-11', 8059],
          // ['2023-08-12', 7374],
          // ['2023-08-13', 7322],
          // ['2023-08-14', 7582],
          // ['2023-08-15', 7860],
          // ['2023-08-16', 7908],
          // ['2023-08-17', 8472],
          // ['2023-08-18', 8596],
          // ['2023-08-19', 7391],
          // ['2023-08-20', 7133],
          // ['2023-08-21', 8474],
          // ['2023-08-22', 8924],
          // ['2023-08-23', 8788],
          // ['2023-08-24', 8515],
          // ['2023-08-25', 5147],
          // ['2023-08-26', 7396],
          // ['2023-08-27', 3539],
          // ['2023-08-28', 8314],
          // ['2023-08-29', 9173],
          // ['2023-08-30', 7405],
          // ['2023-08-31', 9553],
          // ['2023-09-01', 9193],
          // ['2023-09-02', 6738],
          // ['2023-09-03', 3086],
          // ['2023-09-04', 8937],
          // ['2023-09-05', 9924],
          // ['2023-09-06', 9811],
          // ['2023-09-07', 9668],
          // ['2023-09-08', 8461],
          // ['2023-09-09', 7562],
          // ['2023-09-10', 7367],
          // ['2023-09-12', 9147],
          // ['2023-09-13', 7935],
          // ['2023-09-14', 10276],
          // ['2023-09-15', 6305],
          // ['2023-09-16', 7428],
          // ['2023-09-17', 8528],
          // ['2023-09-18', 9843],
          // ['2023-09-19', 4944],
          // ['2023-09-20', 7983],
          // ['2023-09-21', 10047],
          // ['2023-09-22', 9625],
          // ['2023-09-23', 8070],
          // ['2023-09-24', 8274],
          // ['2023-09-25', 9648],
          // ['2023-09-26', 10546],
          // ['2023-09-27', 10964],
          // ['2023-09-28', 10919],
          // ['2023-09-29', 10353],
          // ['2023-09-30', 8717],
          // ['2023-10-01', 9467],
          // ['2023-10-02', 9792],
          // ['2023-10-03', 10892],
          // ['2023-10-04', 10929],
          // ['2023-10-05', 10788],
          // ['2023-10-06', 10077],
          // ['2023-10-07', 8566],
          // ['2023-10-22', 3564],
          // ['2023-10-23', 8345],
          // ['2023-10-24', 9800],
          // ['2023-10-25', 9902],
          // ['2023-10-26', 10487],
          // ['2023-10-27', 8256],
          // ['2023-10-28', 8389],
          // ['2023-10-29', 4492],
          // ['2023-10-30', 7991],
          // ['2023-10-31', 9572],
          // ['2023-11-01', 8296],
          // ['2023-11-02', 7008],
          // ['2023-11-03', 7446],
          // ['2023-11-04', 6499],
          // ['2023-11-05', 6414],
          // ['2023-11-06', 8649],
          // ['2023-11-07', 9255],
          // ['2023-11-08', 5343],
          // ['2023-11-09', 7593],
          // ['2023-11-10', 5545],
          // ['2023-11-11', 7360],
          // ['2023-11-12', 7611],
          // ['2023-11-13', 8723],
          // ['2023-11-14', 9562],
          // ['2023-11-15', 9048],
          // ['2023-11-16', 9356],
          // ['2023-11-17', 8637],
          // ['2023-11-18', 7613],
          // ['2023-11-19', 7601],
          // ['2023-11-20', 8582],
          // ['2023-11-21', 8363],
          // ['2023-11-22', 8912],
          // ['2023-11-23', 8927],
          // ['2023-11-24', 6312],
          // ['2023-11-25', 6684],
          // ['2023-11-26', 6486],
          // ['2023-11-27', 8024],
          // ['2023-11-28', 8231],
          // ['2023-11-29', 7523],
          // ['2023-11-30', 8626],
          // ['2023-12-01', 7321],
          // ['2023-12-02', 6886],
          // ['2023-12-03', 6286],
          // ['2023-12-04', 7046],
          // ['2023-12-05', 7657],
          // ['2023-12-06', 6144],
          // ['2023-12-07', 5997],
          // ['2023-12-08', 5370],
          // ['2023-12-09', 5898],
          // ['2023-12-10', 6263],
          // ['2023-12-11', 7701],
          // ['2023-12-12', 8536],
          // ['2023-12-13', 7730],
          // ['2023-12-14', 7930],
          // ['2023-12-15', 7624],
          // ['2023-12-16', 6665],
          // ['2023-12-17', 6114],
          // ['2023-12-18', 7331],
          // ['2023-12-19', 7716],
          // ['2023-12-20', 7289],
          // ['2023-12-21', 7246],
          // ['2023-12-22', 6838],
          // ['2023-12-23', 6055],
          // ['2023-12-24', 5300],
          // ['2023-12-25', 3143],
          // ['2023-12-26', 4773],
          // ['2023-12-27', 5951],
          // ['2023-12-28', 6019],
          // ['2023-12-29', 5755],
          // ['2023-12-30', 5994],
          // ['2023-12-31', 4370],
          // ['2024-01-01', 3273],
          // ['2024-01-02', 6159],
          // ['2024-01-03', 6342],
          // ['2024-01-04', 6826],
          // ['2024-01-05', 4703],
          // ['2024-01-06', 4440],
          // ['2024-01-07', 6057],
          // ['2024-01-08', 7005],
          // ['2024-01-09', 7566],
          // ['2024-01-10', 4222],
          // ['2024-01-11', 6030],
          // ['2024-01-12', 7255],
          // ['2024-01-13', 6431],
          // ['2024-01-14', 6560],
          // ['2024-01-15', 7302],
          // ['2024-01-16', 8018],
          // ['2024-01-17', 7678],
          // ['2024-01-18', 8303],
          // ['2024-01-19', 2383],
          // ['2024-01-20', 6115],
          // ['2024-01-21', 6766],
          // ['2024-01-22', 7535],
          // ['2024-01-23', 8172],
          // ['2024-01-24', 8110],
          // ['2024-01-25', 8473],
          // ['2024-01-26', 8005],
          // ['2024-01-27', 7519],
          // ['2024-01-28', 7148],
          // ['2024-01-29', 7510],
          // ['2024-01-30', 8623],
          // ['2024-01-31', 8727],
          // ['2024-02-01', 8618],
          // ['2024-02-02', 8221],
          // ['2024-02-03', 7468],
          // ['2024-02-04', 7735],
          // ['2024-02-05', 4694],
          // ['2024-02-06', 8455],
          // ['2024-02-07', 8957],
          // ['2024-02-08', 8819],
          // ['2024-02-09', 4108],
          // ['2024-02-10', 5392],
          // ['2024-02-11', 5627],
          // ['2024-02-12', 7536],
          // ['2024-02-13', 8733],
          // ['2024-02-14', 8721],
          // ['2024-02-15', 5466],
          // ['2024-02-16', 7267],
          // ['2024-02-17', 6636],
          // ['2024-02-18', 7915],
          // ['2024-02-19', 8147],
          // ['2024-02-20', 8964],
          // ['2024-02-21', 8770],
          // ['2024-02-22', 8718],
          // ['2024-02-23', 7304],
          // ['2024-02-24', 6474],
          // ['2024-02-25', 6176],
          // ['2024-02-26', 8068],
          // ['2024-02-27', 8741],
          // ['2024-02-28', 8566],
          // ['2024-02-29', 9098],
          // ['2024-03-01', 7764],
          // ['2024-03-02', 7131],
          // ['2024-03-03', 3672],
          // ['2024-03-04', 8075],
          // ['2024-03-05', 8822],
          // ['2024-03-06', 8682],
          // ['2024-03-07', 8538],
          // ['2024-03-08', 6952],
          // ['2024-03-09', 1756],
          // ['2024-03-10', 6578],
          // ['2024-03-11', 8028],
          // ['2024-03-12', 8756],
          // ['2024-03-13', 8659],
          // ['2024-03-14', 9033],
          // ['2024-03-15', 8399],
          // ['2024-03-16', 7991],
          // ['2024-03-17', 7742],
          // ['2024-03-18', 8458],
          // ['2024-03-19', 9265],
          // ['2024-03-20', 9060],
          // ['2024-03-21', 9106],
          // ['2024-03-22', 8507],
          // ['2024-03-23', 8041],
          // ['2024-03-24', 6054],
          // ['2024-03-25', 4066],
          // ['2024-03-26', 3895],
          // ['2024-03-27', 7028],
          // ['2024-03-28', 6541],
          // ['2024-03-29', 6198],
          // ['2024-03-30', 5787],
          // ['2024-03-31', 3409],
          // ['2024-04-01', 6800],
          // ['2024-04-02', 8765],
          // ['2024-04-03', 9380],
          // ['2024-04-04', 9463],
          // ['2024-04-05', 8966],
          // ['2024-04-06', 7993],
          // ['2024-04-07', 6997],
          // ['2024-04-08', 7184],
          // ['2024-04-09', 7545],
          // ['2024-04-10', 8780],
          // ['2024-04-11', 9641],
          // ['2024-04-12', 9056],
          // ['2024-04-13', 8948],
          // ['2024-04-14', 9289],
          // ['2024-04-15', 8990],
          // ['2024-04-16', 9555],
          // ['2024-04-17', 8788],
          // ['2024-04-18', 8409],
          // ['2024-04-19', 8811],
          // ['2024-04-20', 8455],
          // ['2024-04-21', 7936],
          // ['2024-04-22', 4073],
          // ['2024-04-23', 7793],
          // ['2024-04-24', 9200],
          // ['2024-04-25', 8564],
          // ['2024-04-26', 8746],
          // ['2024-04-27', 6352],
          // ['2024-04-28', 4057],
          ['2024-04-29', 1868],
          ['2024-04-30', 5516],
          ['2024-05-01', 8784],
          ['2024-05-02', 8776],
          ['2024-05-03', 8993],
          ['2024-05-04', 8537],
          ['2024-05-05', 8159],
          ['2024-05-06', 8680],
          ['2024-05-07', 9312],
          ['2024-05-08', 9730],
          ['2024-05-09', 9006],
          ['2024-05-10', 8358],
          ['2024-05-11', 8151],
          ['2024-05-12', 8186],
          ['2024-05-13', 9478],
          ['2024-05-14', 5905],
          ['2024-05-15', 6169],
          ['2024-05-16', 8090],
          ['2024-05-17', 8722],
          ['2024-05-18', 5684],
          ['2024-05-19', 8481],
          ['2024-05-20', 7755],
          ['2024-05-21', 9654],
          ['2024-05-22', 9148],
          ['2024-05-23', 9843],
          ['2024-05-24', 9187],
          ['2024-05-25', 8272],
          ['2024-05-26', 8719],
          ['2024-05-27', 8652],
          ['2024-05-28', 10113],
          ['2024-05-29', 10065],
          ['2024-05-30', 10343],
          ['2024-05-31', 9127],
          ['2024-06-01', 7786],
          ['2024-06-02', 8618],
          ['2024-06-03', 9605],
          ['2024-06-04', 10080],
          ['2024-06-05', 10016],
          ['2024-06-06', 15374],
          ['2024-06-07', 14755],
          ['2024-06-08', 12374],
          ['2024-06-09', 10266],
          ['2024-06-10', 11771],
          ['2024-06-11', 8244],
          ['2024-06-12', 11490],
          ['2024-06-13', 14138],
          ['2024-06-14', 14098],
          ['2024-06-15', 12843],
          ['2024-06-16', 12810],
          ['2024-06-17', 13944],
          ['2024-06-18', 15184],
          ['2024-06-19', 14313],
          ['2024-06-20', 8943],
          ['2024-06-21', 14036],
          ['2024-06-22', 12312],
          ['2024-06-23', 9775],
          ['2024-06-24', 10437],
          ['2024-06-25', 14243],
          ['2024-06-26', 14792],
          ['2024-06-27', 14820],
          ['2024-06-28', 14187],
          ['2024-06-29', 9924],
        ],
      },
    ],
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityPassagesHistory"
    :used-filters="[]"
    :variables="{
      dateRange,
    }"
  >
    <template #title>
      {{ t('cyclability.cycling_passages_history.title') }}
    </template>

    <template #subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRange)) }} - {{ t('from Eco-Compteur') }}
    </template>

    <template #default="{ data }">
      <BarMetric :data="data" />
    </template>
  </MetricCard>
</template>
