<script setup>
defineProps({
  icon: {
    type: String,
    default: null,
  },
})

const { isMobile } = useMobile()
const navigationStore = useNavigationStore()
</script>

<template>
  <Teleport
    v-if="isMobile && navigationStore.sidebarIsMounted"
    to="#teleport-sidebar-controls"
  >
    <MobileActionButton
      :icon="icon"
      class="ml-2"
    >
      <slot name="mobile" />
    </MobileActionButton>
  </Teleport>
  <slot :is-mobile="isMobile" />
</template>
