export enum VehicleTypes {
  ALL = 'all',
  STATION = 'station',
  BIKE = 'bike',
  SCOOTER = 'scooter',
  MOTORSCOOTER = 'moped',
  CAR = 'car',
  OTHER = 'other',
}

export enum VehicleTypesData {
  ALL = 'ALL',
  STATION = 'STATION',
  BIKE = 'BIKE',
  SCOOTER = 'SCOOTER',
  MOTORSCOOTER = 'MOTORSCOOTER',
  CAR = 'CAR',
  OTHER = 'OTHER',
}

export type VehicleType = `${VehicleTypes}`
export type VehicleTypeData = `${keyof typeof VehicleTypes}`
export type AnyVehicleTypes = VehicleType | VehicleTypeData | Lowercase<VehicleTypeData>
