<script setup lang="ts">
import type { DateRange } from '@/stores/filters'

defineOptions({
  name: 'CityCyclabilitySharedBikesPerfMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { writeCache } = useWriteCacheForMetric('cityCyclabilitySharedBikesPerf')

const dateRange = {
  start: new Date(formatDate('2024-04-29', DATE_FORMAT)),
  end: new Date(formatDate('2024-06-29', DATE_FORMAT)),
}

const { translateDate } = useDateTranslation()

function translateRange(range: DateRange) {
  return {
    start: translateDate(new Date(range.start), DATES_FORMATS.FULL),
    end: translateDate(new Date(range.end), DATES_FORMATS.FULL),
  }
}

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
    // dateRange: dateRange.value,
  }, {
    series: [
      {
        name: 'station',
        label: 'Station-based bikes',
        color: '#bbc9f5',
        data: [
          // ['2023-04-21', 62697],
          // ['2023-04-22', 53372],
          // ['2023-04-23', 48450],
          // ['2023-04-24', 57728],
          // ['2023-04-25', 60061],
          // ['2023-04-26', 62065],
          // ['2023-04-27', 62649],
          // ['2023-04-28', 63808],
          // ['2023-04-29', 48684],
          // ['2023-04-30', 35759],
          // ['2023-05-01', 41672],
          // ['2023-05-02', 60651],
          // ['2023-05-03', 61154],
          // ['2023-05-04', 62388],
          // ['2023-05-05', 64058],
          // ['2023-05-06', 55200],
          // ['2023-05-07', 44108],
          // ['2023-05-08', 58125],
          // ['2023-05-09', 57540],
          // ['2023-05-10', 63439],
          // ['2023-05-11', 60679],
          // ['2023-05-12', 51751],
          // ['2023-05-13', 44257],
          // ['2023-05-14', 41007],
          // ['2023-05-15', 59637],
          // ['2023-05-16', 63608],
          // ['2023-05-17', 64639],
          // ['2023-05-18', 64705],
          // ['2023-05-19', 55585],
          // ['2023-05-20', 52553],
          // ['2023-05-21', 46144],
          // ['2023-05-22', 57918],
          // ['2023-05-23', 61468],
          // ['2023-05-24', 65117],
          // ['2023-05-25', 36248],
          // ['2023-05-26', 66357],
          // ['2023-05-27', 54488],
          // ['2023-05-28', 48597],
          // ['2023-05-29', 60431],
          // ['2023-05-30', 63763],
          // ['2023-05-31', 65860],
          // ['2023-06-01', 64462],
          // ['2023-06-02', 64581],
          // ['2023-06-03', 50352],
          // ['2023-06-04', 45040],
          // ['2023-06-05', 46321],
          // ['2023-06-06', 61897],
          // ['2023-06-07', 63065],
          // ['2023-06-08', 64747],
          // ['2023-06-09', 63730],
          // ['2023-06-10', 56425],
          // ['2023-06-11', 46428],
          // ['2023-06-12', 57321],
          // ['2023-06-13', 57938],
          // ['2023-06-14', 62129],
          // ['2023-06-15', 63763],
          // ['2023-06-16', 59263],
          // ['2023-06-17', 52880],
          // ['2023-06-18', 46444],
          // ['2023-06-19', 57479],
          // ['2023-06-20', 62975],
          // ['2023-06-21', 64333],
          // ['2023-06-22', 61780],
          // ['2023-06-23', 55415],
          // ['2023-06-24', 44744],
          // ['2023-06-25', 42502],
          // ['2023-06-26', 56008],
          // ['2023-06-27', 57716],
          // ['2023-06-28', 60185],
          // ['2023-06-29', 60259],
          // ['2023-06-30', 59020],
          // ['2023-07-01', 49503],
          // ['2023-07-02', 46030],
          // ['2023-07-03', 56376],
          // ['2023-07-04', 58741],
          // ['2023-07-05', 61668],
          // ['2023-07-06', 62869],
          // ['2023-07-07', 62803],
          // ['2023-07-08', 52781],
          // ['2023-07-09', 48236],
          // ['2023-07-10', 57850],
          // ['2023-07-11', 60514],
          // ['2023-07-12', 59066],
          // ['2023-07-13', 59850],
          // ['2023-07-14', 57593],
          // ['2023-07-15', 47087],
          // ['2023-07-16', 44725],
          // ['2023-07-17', 55095],
          // ['2023-07-18', 55795],
          // ['2023-07-19', 59157],
          // ['2023-07-20', 59921],
          // ['2023-07-21', 58612],
          // ['2023-07-22', 47050],
          // ['2023-07-23', 44321],
          // ['2023-07-24', 53211],
          // ['2023-07-25', 47610],
          // ['2023-07-26', 57324],
          // ['2023-07-27', 58348],
          // ['2023-07-28', 56392],
          // ['2023-07-29', 46614],
          // ['2023-07-30', 43818],
          // ['2023-07-31', 46254],
          // ['2023-08-01', 54666],
          // ['2023-08-02', 55526],
          // ['2023-08-03', 53642],
          // ['2023-08-04', 53507],
          // ['2023-08-05', 44599],
          // ['2023-08-06', 36775],
          // ['2023-08-07', 46632],
          // ['2023-08-08', 49561],
          // ['2023-08-09', 51202],
          // ['2023-08-10', 51473],
          // ['2023-08-11', 49212],
          // ['2023-08-12', 43107],
          // ['2023-08-13', 39395],
          // ['2023-08-14', 44273],
          // ['2023-08-15', 42111],
          // ['2023-08-16', 49260],
          // ['2023-08-17', 50417],
          // ['2023-08-18', 48808],
          // ['2023-08-19', 43384],
          // ['2023-08-20', 41474],
          // ['2023-08-21', 48271],
          // ['2023-08-22', 51289],
          // ['2023-08-23', 51143],
          // ['2023-08-24', 52806],
          // ['2023-08-25', 52392],
          // ['2023-08-26', 38053],
          // ['2023-08-27', 22176],
          // ['2023-08-28', 52359],
          // ['2023-08-29', 56112],
          // ['2023-08-30', 55322],
          // ['2023-08-31', 58224],
          // ['2023-09-01', 58922],
          // ['2023-09-02', 49482],
          // ['2023-09-03', 44944],
          // ['2023-09-04', 59118],
          // ['2023-09-05', 61490],
          // ['2023-09-06', 63124],
          // ['2023-09-07', 66267],
          // ['2023-09-08', 65674],
          // ['2023-09-09', 55277],
          // ['2023-09-10', 49914],
          // ['2023-09-11', 50708],
          // ['2023-09-12', 63106],
          // ['2023-09-13', 66736],
          // ['2023-09-14', 66647],
          // ['2023-09-15', 46705],
          // ['2023-09-16', 56450],
          // ['2023-09-17', 51178],
          // ['2023-09-18', 61600],
          // ['2023-09-19', 63697],
          // ['2023-09-20', 61356],
          // ['2023-09-21', 66688],
          // ['2023-09-22', 67439],
          // ['2023-09-23', 57901],
          // ['2023-09-24', 52602],
          // ['2023-09-25', 51074],
          // ['2023-09-26', 61324],
          // ['2023-09-27', 65142],
          // ['2023-09-28', 65551],
          // ['2023-09-29', 67914],
          // ['2023-09-30', 57599],
          // ['2023-10-01', 51255],
          // ['2023-10-02', 61118],
          // ['2023-10-03', 64214],
          // ['2023-10-04', 66332],
          // ['2023-10-05', 64902],
          // ['2023-10-06', 67249],
          // ['2023-10-07', 56808],
          // ['2023-10-08', 44129],
          // ['2023-10-09', 61466],
          // ['2023-10-10', 64086],
          // ['2023-10-11', 63983],
          // ['2023-10-12', 53555],
          // ['2023-10-13', 57342],
          // ['2023-10-14', 51160],
          // ['2023-10-15', 40539],
          // ['2023-10-16', 47921],
          // ['2023-10-17', 61081],
          // ['2023-10-18', 61013],
          // ['2023-10-19', 55204],
          // ['2023-10-20', 61285],
          // ['2023-10-21', 54673],
          // ['2023-10-22', 46959],
          // ['2023-10-23', 57540],
          // ['2023-10-24', 59541],
          // ['2023-10-25', 62542],
          // ['2023-10-26', 62664],
          // ['2023-10-27', 63888],
          // ['2023-10-28', 56107],
          // ['2023-10-29', 48330],
          // ['2023-10-30', 52448],
          // ['2023-10-31', 62059],
          // ['2023-11-01', 51382],
          // ['2023-11-02', 53046],
          // ['2023-11-03', 56814],
          // ['2023-11-04', 45060],
          // ['2023-11-05', 44101],
          // ['2023-11-06', 56385],
          // ['2023-11-07', 58245],
          // ['2023-11-08', 58997],
          // ['2023-11-09', 58033],
          // ['2023-11-10', 59173],
          // ['2023-11-11', 50801],
          // ['2023-11-12', 45500],
          // ['2023-11-13', 56860],
          // ['2023-11-14', 53680],
          // ['2023-11-15', 43369],
          // ['2023-11-16', 43476],
          // ['2023-11-17', 43136],
          // ['2023-11-18', 35459],
          // ['2023-11-19', 29927],
          // ['2023-11-20', 36518],
          // ['2023-11-21', 35800],
          // ['2023-11-22', 42601],
          // ['2023-11-23', 50666],
          // ['2023-11-24', 53033],
          // ['2023-11-25', 45636],
          // ['2023-11-26', 39253],
          // ['2023-11-27', 50913],
          // ['2023-11-28', 54600],
          // ['2023-11-29', 50262],
          // ['2023-11-30', 56671],
          // ['2023-12-01', 55180],
          // ['2023-12-02', 45729],
          // ['2023-12-03', 37886],
          // ['2023-12-04', 50633],
          // ['2023-12-05', 56467],
          // ['2023-12-06', 41719],
          // ['2023-12-07', 44785],
          // ['2023-12-08', 37334],
          // ['2023-12-09', 39863],
          // ['2023-12-10', 39351],
          // ['2023-12-11', 56286],
          // ['2023-12-12', 59896],
          // ['2023-12-13', 55308],
          // ['2023-12-14', 60811],
          // ['2023-12-15', 56544],
          // ['2023-12-16', 50726],
          // ['2023-12-17', 41639],
          // ['2023-12-18', 55189],
          // ['2023-12-19', 57193],
          // ['2023-12-20', 57497],
          // ['2023-12-21', 53812],
          // ['2023-12-22', 52262],
          // ['2023-12-23', 41706],
          // ['2023-12-24', 29538],
          // ['2023-12-25', 23565],
          // ['2023-12-26', 26673],
          // ['2023-12-27', 40125],
          // ['2023-12-28', 49526],
          // ['2023-12-29', 40653],
          // ['2023-12-30', 37294],
          // ['2023-12-31', 34980],
          // ['2024-01-01', 28995],
          // ['2024-01-02', 39419],
          // ['2024-01-03', 44016],
          // ['2024-01-04', 46776],
          // ['2024-01-05', 37053],
          // ['2024-01-06', 32359],
          // ['2024-01-07', 34451],
          // ['2024-01-08', 51384],
          // ['2024-01-09', 53370],
          // ['2024-01-10', 38925],
          // ['2024-01-11', 52119],
          // ['2024-01-12', 55207],
          // ['2024-01-13', 44938],
          // ['2024-01-14', 38804],
          // ['2024-01-15', 54104],
          // ['2024-01-16', 58221],
          // ['2024-01-17', 58829],
          // ['2024-01-18', 60332],
          // ['2024-01-19', 32461],
          // ['2024-01-20', 43118],
          // ['2024-01-21', 37950],
          // ['2024-01-22', 51576],
          // ['2024-01-23', 54791],
          // ['2024-01-24', 56729],
          // ['2024-01-25', 58105],
          // ['2024-01-26', 58755],
          // ['2024-01-27', 46735],
          // ['2024-01-28', 38579],
          // ['2024-01-29', 52642],
          // ['2024-01-30', 56834],
          // ['2024-01-31', 58507],
          // ['2024-02-01', 58306],
          // ['2024-02-02', 59544],
          // ['2024-02-03', 48133],
          // ['2024-02-04', 42435],
          // ['2024-02-05', 55712],
          // ['2024-02-06', 52690],
          // ['2024-02-07', 58830],
          // ['2024-02-08', 59562],
          // ['2024-02-09', 47962],
          // ['2024-02-10', 40188],
          // ['2024-02-11', 38931],
          // ['2024-02-12', 51990],
          // ['2024-02-13', 56405],
          // ['2024-02-14', 60437],
          // ['2024-02-15', 60057],
          // ['2024-02-16', 52781],
          // ['2024-02-17', 46523],
          // ['2024-02-18', 40819],
          // ['2024-02-19', 55598],
          // ['2024-02-20', 58214],
          // ['2024-02-21', 59365],
          // ['2024-02-22', 59224],
          // ['2024-02-23', 55897],
          // ['2024-02-24', 43134],
          // ['2024-02-25', 36754],
          // ['2024-02-26', 55205],
          // ['2024-02-27', 57668],
          // ['2024-02-28', 58112],
          // ['2024-02-29', 61349],
          // ['2024-03-01', 58688],
          // ['2024-03-02', 44595],
          // ['2024-03-03', 27940],
          // ['2024-03-04', 55291],
          // ['2024-03-05', 52776],
          // ['2024-03-06', 59559],
          // ['2024-03-07', 58207],
          // ['2024-03-08', 58512],
          // ['2024-03-09', 19860],
          // ['2024-03-10', 29817],
          // ['2024-03-11', 55034],
          // ['2024-03-12', 57424],
          // ['2024-03-13', 60003],
          // ['2024-03-14', 60091],
          // ['2024-03-15', 60319],
          // ['2024-03-16', 50108],
          // ['2024-03-17', 43204],
          // ['2024-03-18', 55016],
          // ['2024-03-19', 58815],
          // ['2024-03-20', 59196],
          // ['2024-03-21', 60296],
          // ['2024-03-22', 62451],
          // ['2024-03-23', 49624],
          // ['2024-03-24', 36946],
          // ['2024-03-25', 32450],
          // ['2024-03-26', 37174],
          // ['2024-03-27', 47401],
          // ['2024-03-28', 45190],
          // ['2024-03-29', 36226],
          // ['2024-03-30', 34499],
          // ['2024-03-31', 19468],
          // ['2024-04-01', 36416],
          // ['2024-04-02', 47440],
          // ['2024-04-03', 59984],
          // ['2024-04-04', 61092],
          // ['2024-04-05', 62564],
          // ['2024-04-06', 51864],
          // ['2024-04-07', 45184],
          // ['2024-04-08', 58446],
          // ['2024-04-09', 60801],
          // ['2024-04-10', 65784],
          // ['2024-04-11', 64660],
          // ['2024-04-12', 65966],
          // ['2024-04-13', 56019],
          // ['2024-04-14', 49086],
          // ['2024-04-15', 60415],
          // ['2024-04-16', 61080],
          // ['2024-04-17', 59606],
          // ['2024-04-18', 61145],
          // ['2024-04-19', 63757],
          // ['2024-04-20', 54550],
          // ['2024-04-21', 44299],
          // ['2024-04-22', 34332],
          // ['2024-04-23', 56416],
          // ['2024-04-24', 61337],
          // ['2024-04-25', 60905],
          // ['2024-04-26', 63090],
          // ['2024-04-27', 50789],
          // ['2024-04-28', 35966],
          ['2024-04-29', 42170],
          ['2024-04-30', 59113],
          ['2024-05-01', 51724],
          ['2024-05-02', 61604],
          ['2024-05-03', 63207],
          ['2024-05-04', 53293],
          ['2024-05-05', 46604],
          ['2024-05-06', 58068],
          ['2024-05-07', 60566],
          ['2024-05-08', 61729],
          ['2024-05-09', 64682],
          ['2024-05-10', 65338],
          ['2024-05-11', 55637],
          ['2024-05-12', 47589],
          ['2024-05-13', 58234],
          ['2024-05-14', 44853],
          ['2024-05-15', 55105],
          ['2024-05-16', 55019],
          ['2024-05-17', 54995],
          ['2024-05-18', 47516],
          ['2024-05-19', 44024],
          ['2024-05-20', 42917],
          ['2024-05-21', 60355],
          ['2024-05-22', 61480],
          ['2024-05-23', 63691],
          ['2024-05-24', 62965],
          ['2024-05-25', 53021],
          ['2024-05-26', 46593],
          ['2024-05-27', 59239],
          ['2024-05-28', 63578],
          ['2024-05-29', 66983],
          ['2024-05-30', 66529],
          ['2024-05-31', 65519],
          ['2024-06-01', 49896],
          ['2024-06-02', 46045],
          ['2024-06-03', 59735],
          ['2024-06-04', 62841],
          ['2024-06-05', 65802],
          ['2024-06-06', 66301],
          ['2024-06-07', 66699],
          ['2024-06-08', 54433],
          ['2024-06-09', 46931],
          ['2024-06-10', 53145],
          ['2024-06-11', 33647],
          ['2024-06-12', 51422],
          ['2024-06-13', 61075],
          ['2024-06-14', 62804],
          ['2024-06-15', 55517],
          ['2024-06-16', 48305],
          ['2024-06-17', 61767],
          ['2024-06-18', 64539],
          ['2024-06-19', 64682],
          ['2024-06-20', 55304],
          ['2024-06-21', 65485],
          ['2024-06-22', 49253],
          ['2024-06-23', 38242],
          ['2024-06-24', 44735],
          ['2024-06-25', 58635],
          ['2024-06-26', 64301],
          ['2024-06-27', 66786],
          ['2024-06-28', 64685],
          ['2024-06-29', 51690],
        ],
        __typename: 'TimelineSerie',
      },
      {
        name: 'free-floating',
        label: 'Free-floating bikes',
        color: '#5578e6',
        data: [
          // ['2023-04-21', 962],
          // ['2023-04-22', 1048],
          // ['2023-04-23', 1008],
          // ['2023-04-24', 905],
          // ['2023-04-25', 933],
          // ['2023-04-26', 845],
          // ['2023-04-27', 986],
          // ['2023-04-28', 1211],
          // ['2023-04-29', 1283],
          // ['2023-04-30', 1149],
          // ['2023-05-01', 1157],
          // ['2023-05-02', 1108],
          // ['2023-05-03', 1062],
          // ['2023-05-04', 1113],
          // ['2023-05-05', 1147],
          // ['2023-05-06', 1253],
          // ['2023-05-07', 1143],
          // ['2023-05-08', 1140],
          // ['2023-05-09', 805],
          // ['2023-05-10', 963],
          // ['2023-05-11', 964],
          // ['2023-05-12', 716],
          // ['2023-05-13', 792],
          // ['2023-05-14', 834],
          // ['2023-05-15', 975],
          // ['2023-05-16', 978],
          // ['2023-05-17', 965],
          // ['2023-05-18', 1017],
          // ['2023-05-19', 820],
          // ['2023-05-20', 1152],
          // ['2023-05-21', 1144],
          // ['2023-05-22', 1055],
          // ['2023-05-23', 1046],
          // ['2023-05-24', 980],
          // ['2023-05-25', 585],
          // ['2023-05-26', 1121],
          // ['2023-05-27', 1417],
          // ['2023-05-28', 1255],
          // ['2023-05-29', 897],
          // ['2023-05-30', 1090],
          // ['2023-05-31', 994],
          // ['2023-06-01', 1143],
          // ['2023-06-02', 1267],
          // ['2023-06-03', 1322],
          // ['2023-06-04', 1171],
          // ['2023-06-05', 1220],
          // ['2023-06-06', 1203],
          // ['2023-06-07', 1102],
          // ['2023-06-08', 1413],
          // ['2023-06-09', 1493],
          // ['2023-06-10', 1548],
          // ['2023-06-11', 1661],
          // ['2023-06-12', 1348],
          // ['2023-06-13', 1051],
          // ['2023-06-14', 1321],
          // ['2023-06-15', 1480],
          // ['2023-06-16', 1458],
          // ['2023-06-17', 1770],
          // ['2023-06-18', 1733],
          // ['2023-06-19', 1355],
          // ['2023-06-20', 1387],
          // ['2023-06-21', 1400],
          // ['2023-06-22', 449],
          // ['2023-06-23', 281],
          // ['2023-06-24', 276],
          // ['2023-06-25', 199],
          // ['2023-06-26', 288],
          // ['2023-06-27', 319],
          // ['2023-06-28', 295],
          // ['2023-06-29', 371],
          // ['2023-06-30', 309],
          // ['2023-07-01', 297],
          // ['2023-07-02', 187],
          // ['2023-07-03', 225],
          // ['2023-07-04', 236],
          // ['2023-07-05', 238],
          // ['2023-07-06', 258],
          // ['2023-07-07', 192],
          // ['2023-07-08', 297],
          // ['2023-07-09', 250],
          // ['2023-07-10', 241],
          // ['2023-07-11', 320],
          // ['2023-07-12', 258],
          // ['2023-07-13', 260],
          // ['2023-07-14', 458],
          // ['2023-07-15', 308],
          // ['2023-07-16', 232],
          // ['2023-07-17', 274],
          // ['2023-07-18', 324],
          // ['2023-07-19', 327],
          // ['2023-07-20', 324],
          // ['2023-07-21', 290],
          // ['2023-07-22', 253],
          // ['2023-07-23', 237],
          // ['2023-07-24', 288],
          // ['2023-07-25', 447],
          // ['2023-07-26', 281],
          // ['2023-07-27', 339],
          // ['2023-07-28', 332],
          // ['2023-07-29', 323],
          // ['2023-07-30', 281],
          // ['2023-07-31', 276],
          // ['2023-08-01', 266],
          // ['2023-08-02', 415],
          // ['2023-08-03', 268],
          // ['2023-08-04', 271],
          // ['2023-08-05', 277],
          // ['2023-08-06', 158],
          // ['2023-08-07', 351],
          // ['2023-08-08', 282],
          // ['2023-08-09', 297],
          // ['2023-08-10', 277],
          // ['2023-08-11', 357],
          // ['2023-08-12', 273],
          // ['2023-08-13', 261],
          // ['2023-08-14', 272],
          // ['2023-08-15', 272],
          // ['2023-08-16', 265],
          // ['2023-08-17', 356],
          // ['2023-08-18', 285],
          // ['2023-08-19', 213],
          // ['2023-08-20', 251],
          // ['2023-08-21', 265],
          // ['2023-08-22', 296],
          // ['2023-08-23', 347],
          // ['2023-08-24', 295],
          // ['2023-08-25', 251],
          // ['2023-08-26', 263],
          // ['2023-08-27', 227],
          // ['2023-08-28', 356],
          // ['2023-08-29', 307],
          // ['2023-08-30', 260],
          // ['2023-08-31', 267],
          // ['2023-09-01', 280],
          // ['2023-09-02', 280],
          // ['2023-09-03', 411],
          // ['2023-09-04', 390],
          // ['2023-09-05', 270],
          // ['2023-09-06', 303],
          // ['2023-09-07', 257],
          // ['2023-09-08', 318],
          // ['2023-09-09', 252],
          // ['2023-09-10', 262],
          // ['2023-09-11', 138],
          // ['2023-09-12', 190],
          // ['2023-09-13', 317],
          // ['2023-09-14', 315],
          // ['2023-09-15', 228],
          // ['2023-09-16', 258],
          // ['2023-09-17', 230],
          // ['2023-09-18', 336],
          // ['2023-09-19', 289],
          // ['2023-09-20', 300],
          // ['2023-09-21', 390],
          // ['2023-09-22', 356],
          // ['2023-09-23', 354],
          // ['2023-09-24', 376],
          // ['2023-09-25', 271],
          // ['2023-09-26', 309],
          // ['2023-09-27', 307],
          // ['2023-09-28', 357],
          // ['2023-09-29', 384],
          // ['2023-09-30', 348],
          // ['2023-10-01', 303],
          // ['2023-10-02', 214],
          // ['2023-10-03', 361],
          // ['2023-10-04', 448],
          // ['2023-10-05', 399],
          // ['2023-10-06', 367],
          // ['2023-10-07', 325],
          // ['2023-10-08', 318],
          // ['2023-10-09', 280],
          // ['2023-10-10', 309],
          // ['2023-10-11', 400],
          // ['2023-10-12', 288],
          // ['2023-10-13', 290],
          // ['2023-10-14', 199],
          // ['2023-10-15', 163],
          // ['2023-10-16', 216],
          // ['2023-10-17', 273],
          // ['2023-10-18', 329],
          // ['2023-10-19', 422],
          // ['2023-10-20', 361],
          // ['2023-10-21', 305],
          // ['2023-10-22', 233],
          // ['2023-10-23', 280],
          // ['2023-10-24', 279],
          // ['2023-10-25', 319],
          // ['2023-10-26', 367],
          // ['2023-10-27', 379],
          // ['2023-10-28', 299],
          // ['2023-10-29', 256],
          // ['2023-10-30', 267],
          // ['2023-10-31', 302],
          // ['2023-11-01', 303],
          // ['2023-11-02', 497],
          // ['2023-11-03', 560],
          // ['2023-11-04', 243],
          // ['2023-11-05', 404],
          // ['2023-11-06', 330],
          // ['2023-11-07', 217],
          // ['2023-11-08', 243],
          // ['2023-11-09', 208],
          // ['2023-11-10', 308],
          // ['2023-11-11', 258],
          // ['2023-11-12', 211],
          // ['2023-11-13', 241],
          // ['2023-11-14', 219],
          // ['2023-11-15', 234],
          // ['2023-11-16', 260],
          // ['2023-11-17', 234],
          // ['2023-11-18', 201],
          // ['2023-11-19', 180],
          // ['2023-11-20', 208],
          // ['2023-11-21', 196],
          // ['2023-11-22', 345],
          // ['2023-11-23', 228],
          // ['2023-11-24', 351],
          // ['2023-11-25', 150],
          // ['2023-11-26', 140],
          // ['2023-11-27', 169],
          // ['2023-11-28', 329],
          // ['2023-11-29', 162],
          // ['2023-11-30', 270],
          // ['2023-12-01', 368],
          // ['2023-12-02', 245],
          // ['2023-12-03', 155],
          // ['2023-12-04', 179],
          // ['2023-12-05', 177],
          // ['2023-12-06', 99],
          // ['2023-12-07', 138],
          // ['2023-12-08', 171],
          // ['2023-12-09', 187],
          // ['2023-12-10', 170],
          // ['2023-12-11', 191],
          // ['2023-12-12', 227],
          // ['2023-12-13', 226],
          // ['2023-12-14', 172],
          // ['2023-12-15', 211],
          // ['2023-12-16', 199],
          // ['2023-12-17', 142],
          // ['2023-12-18', 145],
          // ['2023-12-19', 140],
          // ['2023-12-20', 160],
          // ['2023-12-21', 189],
          // ['2023-12-22', 218],
          // ['2023-12-23', 170],
          // ['2023-12-24', 92],
          // ['2023-12-25', 76],
          // ['2023-12-26', 90],
          // ['2023-12-27', 119],
          // ['2023-12-28', 141],
          // ['2023-12-29', 117],
          // ['2023-12-30', 121],
          // ['2023-12-31', 111],
          // ['2024-01-01', 104],
          // ['2024-01-02', 88],
          // ['2024-01-03', 108],
          // ['2024-01-04', 108],
          // ['2024-01-05', 95],
          // ['2024-01-06', 106],
          // ['2024-01-07', 85],
          // ['2024-01-08', 113],
          // ['2024-01-09', 125],
          // ['2024-01-10', 88],
          // ['2024-01-11', 163],
          // ['2024-01-12', 174],
          // ['2024-01-13', 151],
          // ['2024-01-14', 125],
          // ['2024-01-15', 107],
          // ['2024-01-16', 100],
          // ['2024-01-17', 110],
          // ['2024-01-18', 150],
          // ['2024-01-19', 101],
          // ['2024-01-20', 157],
          // ['2024-01-21', 157],
          // ['2024-01-22', 171],
          // ['2024-01-23', 180],
          // ['2024-01-24', 139],
          // ['2024-01-25', 151],
          // ['2024-01-26', 180],
          // ['2024-01-27', 180],
          // ['2024-01-28', 146],
          // ['2024-01-29', 158],
          // ['2024-01-30', 169],
          // ['2024-01-31', 176],
          // ['2024-02-01', 204],
          // ['2024-02-02', 198],
          // ['2024-02-03', 245],
          // ['2024-02-04', 148],
          // ['2024-02-05', 215],
          // ['2024-02-06', 177],
          // ['2024-02-07', 276],
          // ['2024-02-08', 167],
          // ['2024-02-09', 127],
          // ['2024-02-10', 174],
          // ['2024-02-11', 282],
          // ['2024-02-12', 234],
          // ['2024-02-13', 192],
          // ['2024-02-14', 191],
          // ['2024-02-15', 213],
          // ['2024-02-16', 174],
          // ['2024-02-17', 188],
          // ['2024-02-18', 187],
          // ['2024-02-19', 170],
          // ['2024-02-20', 210],
          // ['2024-02-21', 176],
          // ['2024-02-22', 204],
          // ['2024-02-23', 215],
          // ['2024-02-24', 171],
          // ['2024-02-25', 152],
          // ['2024-02-26', 194],
          // ['2024-02-27', 202],
          // ['2024-02-28', 199],
          // ['2024-02-29', 229],
          // ['2024-03-01', 229],
          // ['2024-03-02', 179],
          // ['2024-03-03', 329],
          // ['2024-03-04', 202],
          // ['2024-03-05', 230],
          // ['2024-03-06', 204],
          // ['2024-03-07', 191],
          // ['2024-03-08', 268],
          // ['2024-03-09', 123],
          // ['2024-03-10', 182],
          // ['2024-03-11', 278],
          // ['2024-03-12', 227],
          // ['2024-03-13', 251],
          // ['2024-03-14', 259],
          // ['2024-03-15', 222],
          // ['2024-03-16', 229],
          // ['2024-03-17', 197],
          // ['2024-03-18', 214],
          // ['2024-03-19', 200],
          // ['2024-03-20', 191],
          // ['2024-03-21', 185],
          // ['2024-03-22', 190],
          // ['2024-03-23', 205],
          // ['2024-03-24', 145],
          // ['2024-03-25', 100],
          // ['2024-03-26', 159],
          // ['2024-03-27', 198],
          // ['2024-03-28', 262],
          // ['2024-03-29', 127],
          // ['2024-03-30', 172],
          // ['2024-03-31', 133],
          // ['2024-04-01', 262],
          // ['2024-04-02', 189],
          // ['2024-04-03', 224],
          // ['2024-04-04', 189],
          // ['2024-04-05', 194],
          // ['2024-04-06', 244],
          // ['2024-04-07', 197],
          // ['2024-04-08', 229],
          // ['2024-04-09', 304],
          // ['2024-04-10', 247],
          // ['2024-04-11', 262],
          // ['2024-04-12', 278],
          // ['2024-04-13', 269],
          // ['2024-04-14', 245],
          // ['2024-04-15', 225],
          // ['2024-04-16', 258],
          // ['2024-04-17', 228],
          // ['2024-04-18', 200],
          // ['2024-04-19', 258],
          // ['2024-04-20', 215],
          // ['2024-04-21', 160],
          // ['2024-04-22', 112],
          // ['2024-04-23', 191],
          // ['2024-04-24', 183],
          // ['2024-04-25', 208],
          // ['2024-04-26', 235],
          // ['2024-04-27', 182],
          // ['2024-04-28', 109],
          ['2024-04-29', 111],
          ['2024-04-30', 205],
          ['2024-05-01', 202],
          ['2024-05-02', 271],
          ['2024-05-03', 229],
          ['2024-05-04', 228],
          ['2024-05-05', 200],
          ['2024-05-06', 203],
          ['2024-05-07', 239],
          ['2024-05-08', 286],
          ['2024-05-09', 311],
          ['2024-05-10', 321],
          ['2024-05-11', 270],
          ['2024-05-12', 234],
          ['2024-05-13', 260],
          ['2024-05-14', 204],
          ['2024-05-15', 212],
          ['2024-05-16', 221],
          ['2024-05-17', 243],
          ['2024-05-18', 189],
          ['2024-05-19', 186],
          ['2024-05-20', 184],
          ['2024-05-21', 254],
          ['2024-05-22', 221],
          ['2024-05-23', 270],
          ['2024-05-24', 281],
          ['2024-05-25', 251],
          ['2024-05-26', 239],
          ['2024-05-27', 215],
          ['2024-05-28', 208],
          ['2024-05-29', 237],
          ['2024-05-30', 299],
          ['2024-05-31', 356],
          ['2024-06-01', 210],
          ['2024-06-02', 221],
          ['2024-06-03', 226],
          ['2024-06-04', 293],
          ['2024-06-05', 274],
          ['2024-06-06', 319],
          ['2024-06-07', 274],
          ['2024-06-08', 293],
          ['2024-06-09', 200],
          ['2024-06-10', 186],
          ['2024-06-11', 124],
          ['2024-06-12', 222],
          ['2024-06-13', 341],
          ['2024-06-14', 323],
          ['2024-06-15', 298],
          ['2024-06-16', 263],
          ['2024-06-17', 246],
          ['2024-06-18', 281],
          ['2024-06-19', 167],
          ['2024-06-20', 233],
          ['2024-06-21', 287],
          ['2024-06-22', 198],
          ['2024-06-23', 166],
          ['2024-06-24', 245],
          ['2024-06-25', 334],
          ['2024-06-26', 313],
          ['2024-06-27', 307],
          ['2024-06-28', 363],
          ['2024-06-29', 285],
        ],
        __typename: 'TimelineSerie',
      },
    ],
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilitySharedBikesPerf"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.shared_bike_perf.title') }}
    </template>

    <template #subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRange)) }}
    </template>

    <template #default="{ data }">
      <BarMetric
        :data="data"
        :options="{
          color: d => PALETTE_COLORS_WEEK[d.name],
        }"
      />
    </template>
  </MetricCard>
</template>
